$body-bg: #ffffff;
$body-color: #111111;
$link-color:  $body-color;
$link-decoration: none;
$link-hover-color: $body-color;
$link-hover-decoration: $body-color;
$font-size-base: 1rem;
$font-family-base: 'Montserrat', sans-serif;
$headings-margin-bottom: 1.1rem;
$headings-margin-top: 1.1rem;
$enable-rounded: false;
$btn-border-width: 3px;
$font-weight-semi: 600;
$font-weight-extrabold: 800;
$ghost: $white;
$section-padding-top: 6.7rem;
$image-url: "../img/";
$btn-font-weight: $font-weight-semi;
$btn-font-size: $font-size-base + 1.2;
$btn-focus-box-shadow: 0;
$input-btn-padding-y: .845rem;
$btn-padding-y: 0.75rem;
$enable-shadows: true;
$input-btn-focus-width: 0;
$btn-box-shadow: 0 0 0 transparent;
$error-color: #ff3838;

$h1-font-size:                $font-size-base * 3.8;
$h2-font-size:                $font-size-base * 3;
$h3-font-size:                $font-size-base * 2.5;
$h4-font-size:                $font-size-base * 1.95;
$h5-font-size:                $font-size-base * 1.56;
$h6-font-size:                $font-size-base * 1.25;

$btn-primary-color: $body-color;
$btn-primary-bg: transparent;
$btn-primary-border: transparent;

$medium-desktop : 1199px;
$tablet : 992px;
$small-tablet : 768px;
$mobile : 767px;
$mobile-xs : 576px;
$mobile-xxs : 480px;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px
);

$aos-distance: 50px;

$size: 2.4rem;
$size-mobile: 2rem;
$size-tablet: 2.2rem;
