/*@import "../bootstrap/scss/bootstrap";*/
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "custom_variables";
@import "custom-mixin";

@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/reboot";

/*@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/jumbotron";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/media";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/print";*/

@import "custom_variables";
@import "custom-mixin";

* { outline: none !important; }

.background-cover { background-size: cover; }

/*html, body { height:100%;}*/
html { font-size:16px;
  @media(max-width: $tablet) { font-size:14px; }
  @media(max-width: $mobile) { font-size:12px; }
  @media(max-width: $mobile-xs) { /*font-size:13px*/; }
  }
body { background-color: $body-bg; color: $body-color; font-family: $font-family-base; }
h1 { font-size: 2.2rem; }
h2 { font-size: 2rem; }
.news-detail h1 , .work-details h1, .services-page h1, .content-page h1, h3 { font-size: 1.8rem !important; padding-bottom: 1.85rem; position: relative; font-weight: $font-weight-semi;
  &:before { content: ''; position: absolute; bottom: 0; left: 0; border-top: 2px solid #111111; width: 120px; height: 0;
    @include mQ($tablet) { width: 105px; }
    }
  }
h4 { font-size: 1.5rem; text-transform: uppercase; font-weight: 400 !important; margin-bottom: 1rem; line-height: 1.4 !important;
  @include mQ($tablet) { font-size: 1.3rem; } }
h5 { font-size: 1.25rem; @include mQ($tablet) { font-size:1.15rem; } }
h6 { font-size: 1rem; }
p { font-size: 0.9rem; line-height: 1.9;
  @include mQ($mobile) { font-size: 1rem; line-height: 1.7; }
  }
a { transition: 0.3s color ease-in-out, 0.3s opacity ease-in-out; position: relative; display: inline-block; backface-visibility: hidden; opacity: 0.5;
    &:before { position: absolute; top: 100%; left: 0; width: 100%; height: 1px; background: #525252; content: ''; opacity: 0; transition: opacity 0.3s, transform 0.3s; transform: translateY(10px); }
    &:hover { opacity:1;
      &:before { opacity: 1; transform: translateY(0px); }
      }
    }

.loader { display: none; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 62px; height: 12px;
    @include mQ($tablet) { height: 11px; }
    @include mQ($mobile) { height: 9px; }
    i {
      display: block;
      position: absolute;
      top: 0;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: #fff;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
      &:nth-child(1) { left: 6px; animation: lds-ellipsis1 0.6s infinite; }
      &:nth-child(2) { left: 6px; animation: lds-ellipsis2 0.6s infinite; }
      &:nth-child(3) { left: 26px; animation: lds-ellipsis2 0.6s infinite; }
      &:nth-child(4) { left: 45px; animation: lds-ellipsis3 0.6s infinite; }
      @include mQ($tablet) { width: 10px; height: 10px; }
      @include mQ($mobile) { width: 8px; height: 8px; }
      }

  .royaltri-spinner { position: absolute; top: 0; animation-timing-function: cubic-bezier(0, 1, 1, 0); animation: royaltri-logo-spinning 1s infinite;
    .logo , svg { width: 2.4rem; height: 2.4rem;
      @include mQ($tablet) { width: 2.2rem; width: 2.2rem; }
      }
    }

    }

/*Custom Btn Loader Keyframes*/
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
    }
  100% {
    transform: scale(1);
    }
  }
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
    }
  100% {
    transform: scale(0);
    }
  }
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
    }
  100% {
    transform: translate(19px, 0);
    }
  }

@keyframes royaltri-logo-spinning {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    background-color: transparent !important;
    background-image: none !important;
    color: $white !important;
}

svg { backface-visibility: hidden; }

.custom-btn { opacity:1; transition: all 0.5s; position: relative; overflow: hidden; display: inline-block; font-size: $font-size-base; font-weight: $font-weight-semi; min-width: 12rem; border: 2px solid transparent; padding: .75rem 1.5rem; line-height: 1.5; border-radius: 0; text-align: center; margin-top:1.4rem; cursor: pointer;
  @include mQ($tablet) { min-width: 10.5rem; }
  span { position: relative; z-index:2; display: block;
      a { opacity: 1;
        &:before { opacity:0; }
        }
    svg { width: 15px; height: 15px; position: relative; top: 3px; stroke-width: 0.5px; }
    }

  span.loader { position: absolute; display: none; }

  &:hover { text-decoration: none; }
  &:before { height:0; opacity:0; display: none; }
  &:after { content: ''; position: absolute; z-index: -1; transition: opacity 0.3s, height 0.3s; width: 100%; height: 0; top: 50%; left: 50%; background-color: transparent; opacity: 0; -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg); -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg); -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg); transform: translateX(-50%) translateY(-50%) rotate(45deg); }
  &:hover:not([disabled]){
    &:after { height: 620%; opacity: 1; z-index: 0; }
    }

  &.custom-btn-dark { background-color: $body-color; color: $white;
    span { svg {fill: $white; stroke: $white; }
      a { color: $white; }
      }
    &:hover:not([disabled]), &:hover:not(.loading) { color: $body-color; background-color: transparent; border-color: $body-color;
      &:after { background-color: $white;   }
      span {
        svg {fill: $body-color; stroke: $body-color; }
        }
      }
    }

  &.custom-btn-ghost { border-color: $white; color: $white;
    &:after { background-color: rgba(255,255,255,1); }
    &:hover:not([disabled]), &:hover:not(.loading) {
      span { color: $body-color; }
      }
    }

  &.custom-btn-white { background-color: $white; color: $body-color;
    span {svg {stroke: $body-color;} }
    &:hover:not([disabled]), &:hover:not(.loading)  {
      span { color: $white;
        svg { fill: $white; stroke: $white; }
        }
      }

    &:after { background-color: rgba(0,0,0,1); }
    }

  &.custom-btn-primary { background-color: transparent; color: $body-color; border-color: $body-color;
    &:hover:not([disabled]) span { color: $white;
      }
    &:after { background-color: rgba(0,0,0,1); }
    .loader {
      i { background-color: $body-color; }
      }
    }

  &.loading { cursor: default; pointer-events: none;
    .loader { display: block; }
    .text { visibility: hidden; }
    }

  &[disabled] { opacity:0.5; cursor: default; pointer-events: none;
    &:after { display: none; }
    }

  }

@media (max-width: $mobile) {
  .custom-btn {
    span {
      svg { height:11px; }
      }
    }
  }


%section , .section {
  padding: 6.7rem 0;
  h3 { margin-bottom:0; @include mQ($mobile) { margin-bottom:0; } }
  }

@media(max-width: $tablet) {
  %section { padding:5.7rem 0; }
  }

.container-half {
    @include make-container();
    @include make-container-max-widths-half();
    margin:0 auto; padding:0;
  }

.paddb-sm-0 { @include mQ($mobile){ padding-bottom:0 !important; }  }
.paddt-sm-0 { @include mQ($mobile) { padding-top: 0 !important; } }
.paddt-xl-0 { padding-top: 0 !important; }

%nav-arrow {
  &:after { content: ''; position: absolute; }
  .previous {
    &:after { transform: rotate(-135deg) }
    }
  .next {
    &:after { transform: rotate(-135deg) }
    }
  }

.menu-hover {
  a { opacity:1; transition: 0.3s color ease-in-out; position: relative; color: $white; text-decoration: none; display: inline-block; backface-visibility: hidden;
    &:before { position: absolute; top: 100%; left: 0; width: 100%; height: 2px; background: $white; content: ''; opacity: 0; transition: opacity 0.3s, transform 0.3s; transform: translateY(10px); }
    &:hover {
      text-decoration: none; color: $white;
      &:before { opacity: 1; transform: translateY(0px); }
      }
    }
  }

.has-nav {
  .main-header { background: transparent; transform: translateY(0) !important; position: fixed;
    &.clone { background-color: transparent; transform: translateY(-100px) !important; display: none; opacity: 0;
      }
    .logo { svg { fill: $white; } }
    .search {
      a {
        svg { fill: $white; }
        }
      }
    }
  .main-nav{ /*transform: scale(1);*/ opacity: 1; transition: opacity .71s cubic-bezier(.23, 1, .32, 1); z-index:1001; }
  .wrapper, .main-footer { opacity: 0 !important; }
  }

.has-search {
  .main-header{ background: #eeefee; transform: translateY(0) !important; position: fixed;
    &.clone { background-color: transparent; transform: translateY(-100px) !important; display: none; opacity: 0; }
    /*.logo { svg { fill: $white; } }*/
    .search {
      a { background-image: none;
        &:before, &:after {
          $height: .3rem;
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -$height/2;
          margin-left: -$size/2;
          display: block;
          height: $height;
          width: $size;
          border-radius: 0;
          transform-origin: center center;
          background-color: $body-color;
          transition: top 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) .25s, transform .25s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
          @include mQ($mobile) {
            width : $size-mobile; top:13px;
            }
          }
        &:before { transform: rotate(45deg) translateY(0); visibility: visible; opacity:1; transition: 0s transform linear; }
        &:after { transform: rotate(-45deg); }
        svg { visibility: hidden; }
        }
      }
  }
  .search-container { /*transform: scale(1);*/ opacity: 1; transition: opacity .71s cubic-bezier(.23, 1, .32, 1); z-index:100;
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px transparent inset;
      -webkit-text-fill-color: $body-color !important;
    }
    .search-box {position: absolute; top:50%; left:50%; width: 100%; transform: translate(-50%, -50%); z-index:1005; background-color: #eeefee; padding:0 15px; }
    .search-form { display: flex; justify-content: center; margin:0 auto; width:auto;
      form { @include mQ($mobile-xs) { width: 100%; } }
      input[type="text"] { width: 510px; color: $white; border-bottom-color: $body-color; color: $body-color; backface-visibility: hidden;
        @include inputPlaceholder() {
          color: #666666;
          font-weight: 400;
          padding-top: 0;
          }
        }
      button { padding:0; width: $size-tablet; height: $size-tablet; background-image: none;
        svg { width: $size-tablet; height: $size-tablet; @include mQ($mobile) { width: $size-mobile; height: $size-mobile; } }
        &:after { border: none; }
        }
      }
    .search-results { @extend %section; width: 100%; display: none; min-height: calc(100vh - 13.4rem);
      @include mQ($tablet) { min-height: calc(100vh - 11.4rem); }
      ul { padding:0; margin: 0; list-style: none; margin-top:1.8rem;
        li { padding:0.6rem 0; font-size:$font-size-base - 0.15; font-weight:$font-weight-normal;
          @include mQ($mobile) {font-size:$font-size-base;}
          &:first-child { padding-top:0; }
          &:last-child { padding-bottom:0; }
          a { color: $body-color;
            &:hover { color: $body-color; }
            &:before { background-color: $body-color; height: 1px; }
            }
          }
        }
      .loader {
        i { background-color: $body-color; }
        }
      }
      .no-result { text-align: center; }
    }

  @media(max-width: $mobile) {
    .search-container {
      .search-form {
        button { bottom: 8px; }
        }
      .search-results {
        ul {
          li { padding: 0.45rem 0;
            a { &:before { height: 1px; } }
            }
          }
        }
      }
    }

  @media(max-width: $mobile-xs) {
    .search-container {
      .search-form {
         input[type="text"] { width: 100%; }
        }
      .search-results {
        .col-12 {
          &:first-child {
            h3 { margin-top:0; }
            }
          }
        h3 { margin-top: 1.5em;
          }
        }
      }
    }

  .menu-hamburger {
    .menu-hamburger-box {
      &:before, &:after { background-color: $body-color !important; }
    }
  }

  }

.visible-nav {
  .main-header.clone { transform: translateY(0); }
  }

.main-header {
  .container { position: relative; }
  padding:2rem 0 0;
  transition: 0.5s transform linear;
  background-color: transparent;
  position: absolute;
  top:0;
  width: 100%;
  left: 0;
  z-index: 1002;
  &.clone { position: fixed; transform: translateY(-100px); background-color: rgba(255,255,255,0.9); padding: 1.05rem 0; box-shadow: 0 0 5px rgba(17, 17, 17, 0.5);
    .logo { width: 200px; left:-32px;
      }
    .search {margin-top: 2px;}
    }
   .logo {
     width: 261px; height: auto; font-size:0;
    a { display: inline-block; opacity:1;
      &:before { visibility: hidden; }
      }
    svg { fill: $body-color;  width: 100%;  }
    }

  .search { position: absolute; top: 50%; transform: translateY(-50%); right: 90px; font-size: 0; cursor: pointer;
    @include mQ($tablet){ right: 85px; }
    @include mQ($mobile){ right: 70px; }
    a { display: inline-block; width: $size-tablet; height: $size-tablet;  background-size: cover; opacity:1;
      @include mQ($tablet){ width: $size-tablet; height: $size-tablet; }
      @include mQ($mobile){ width: $size-mobile; height: $size-mobile; }
        &:before { visibility: hidden; }
        svg { width: $size-tablet; height: $size-tablet; fill: $body-color; transition: 0.2s opacity ease-in-out;
          @include mQ($mobile){ width: $size-mobile; height: $size-mobile; }
          }
      }
    }

  &.light {
    .logo {
      svg { fill: $white; }
      }
    .search{
      a {
        svg { fill: $white; }
        }
      }
    .menu-hamburger {
      .menu-hamburger-box {
        &:after , &:before { background-color: $white; }
        }
    }
    }
  }

.has-search {
  .main-header{
    .logo {
      svg { fill: $body-color !important; }
      }
    &.light {
      .logo {
        svg {fill: $body-color; }
        }
      .search{
        a { background-image: none;
          svg { opacity:0; }
          }
      }
      .menu-hamburger {
        .menu-hamburger-box {
          &:after, &:before { background-color: $body-color; }
          }
        }
      }
    }
  }

@media (max-width: $tablet) {
  .main-header {
    .logo { width: 220px; }
    }
  }


@media (max-width: $mobile) {
  .main-header {
    .logo { width: 170px !important; }
    .search {
      a { }
      }
    }

  .has-search {
    .search-container {
      .search-box { position: relative; padding: 0; }
      }
    }
  }

.menu-hamburger { position: absolute;
    transform: translateY(-50%);
    top: 50%;
    outline: none !important;
    right: 15px;
    z-index: 1001;
    display: inline-block;
    cursor: pointer;
    transition: 0.5s transform linear, opacity .3s ease, visibility .3s ease;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    padding:0;
    font-size:0;
    @include mQ($mobile-xs){ right: 20px; }
    .menu-hamburger-box {
      height: 1rem;
      width: $size;
      position: relative;
      box-sizing: border-box;
      line-height: $size;
      display: inline-block;
      color: $dark;
      transition: color .3s ease;
      @include mQ($tablet){ width: $size-tablet; line-height: $size-tablet; }
      @include mQ($mobile){ width: $size-mobile; line-height: $size-mobile; top: 2px; }


      &:before, &:after {
        $height: 0.3rem;
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        margin-top: -$height/2;
        margin-left: -$size/2;
        display: block;
        height: $height;
        width: $size;
        border-radius: 0;
        transform-origin: center center;
        background-color: $body-color;
        transition: top 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) .25s, transform .25s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
        @include mQ($mobile){ width: $size-mobile; }
        }

      &:after {
        top: 14px; @include mQ($mobile){ top: 10px; }
        }

      .has-nav &  {
        color: $white;

        &:before, &:after {
          top: 50%;
          transition: top 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, transform .25s cubic-bezier(0.215, 0.61, 0.355, 1) .1s;
          background-color: $white;
          }

        &:before {
          transform: rotate(45deg);
          }

        &:after {
          transform: rotate(-45deg);
          }

        }
      }
    }

.main-nav , .search-container { display: inline-block; text-align: right; position: fixed; overflow: auto; vertical-align: middle; left: 0; top: 0; height:100%; width: 100%; z-index: -1;  opacity: 0; transition: opacity .10s cubic-bezier(.23,1,.32,1); box-sizing: border-box;
  .container { position: relative; height:100vh; }
  .menu-container { width:100%; position: absolute; z-index:10; top:50%; right:15px; transform: translateY(-50%); max-height: 260px; @include mQ($mobile-xs) { right: 20px; } }
  }

  .has-nav {
    .site-nav{
      .vertical-menu li { opacity: 1; transform: translateZ(0); transition: transform .91s cubic-bezier(.165, .84, .44, 1), opacity .91s ease;
        &:nth-child(4n + 1) {
          transition-delay: .8s;
          }
        &:nth-child(4n + 2) {
          transition-delay: .6s;
          }
        &:nth-child(4n + 3) {
          transition-delay: .4s;
          }
        &:nth-child(4n) {
          transition-delay: .2s;
          }
        a { padding-bottom: 4px; &:hover:before {transform: translateY(2px); } }
        }
      }
    }

  .has-nav , .has-search { overflow: hidden; position: relative; }

  .site-nav {
    ul { margin: 0; padding:0; text-align: right; }
    li { list-style-type: none; text-align: right;
      }
    .vertical-menu {
      li { line-height: 1;  margin-bottom: 16px; font-size: $font-size-lg + 1.50; opacity: 0; transform: translate3d(0, -10vh, 0); transition-delay: 1s; transition: transform .31s cubic-bezier(.165, .84, .44, 1), opacity .21s ease; }
      }
    .horizontal-menu {
      margin-top: 30px;
      li { margin-right: 15px; display: inline-block; font-size: 1rem; text-transform: uppercase;
        &:last-child { margin-right: 0; }
        a {
          &:before { height: 1px; }
          }
        }
      }
    .social-icons {
      li { font-size: $font-size-lg + 0.05;
        @include mQ($tablet) {font-size: $font-size-lg + 0.25; }
        a { opacity: 0.8; transition:0.3s opacity linear;
          &:hover {opacity: 1;}
          &:before {height: 0;}
          }
        }
      }
    }


  @media (max-height: 420px) {
    .menu-container {
        .vertical-menu  {
          li { display: inline-block; padding-right: 8px; font-size:1.8rem !important; margin-bottom: 0 !important;
            &:after { content: '/'; color: $white; margin-left:10px;}
            &:last-child { padding-right: 0;
              &:after { margin-left:0; content: ''; }
              }
            }
          }
      }
    }

  .search-container { background: no-repeat center #eeefee; text-align: left;
    &:after { background: none; }
    }

  .clone {
    &.light {
      .logo {
        a {
          svg {fill: $body-color;}
          }
        }
      .search {
        a { /*background-image: url($image-url + "search-icon.png");*/
          svg { fill: $body-color; }
          }
        }
      .menu-hamburger {
        .menu-hamburger-box {
          &:before, &:after { background-color: $body-color; }
          }
        }
      }
    }

  @media (max-width: $tablet){
    .site-nav {
      .vertical-menu {
        li {font-size: $font-size-lg + 1.15;}
        }
      }
    }

  @media (max-width: $mobile) {
    .site-nav {
      .vertical-menu {
        li { margin-bottom: 12px; }
        }
      .horizontal-menu { margin-top:25px;
        li { font-size: $font-size-lg - 0.25; margin-right:10px; }
        &.social-icons {
          li {font-size: $font-size-lg + 0.25;}
          }
        }
      }
    }

  @media (max-width: $mobile-xs) {
    .site-nav {
      .horizontal-menu {
        li { font-size: $font-size-lg - 0.20; margin-right:10px; }
        }
      }
    }

/*Home Slider*/
.home-slider { padding: 12.2rem 0 6.4rem; min-height:636px; position: relative;
  @include mQ($tablet) {padding: 11.5rem 0 6.4rem;}
  @include mQ($mobile) {padding: 10.5rem 0 6rem;}
  &:before { position: absolute; content: ''; top:0; bottom:0; left:0; right:0; height:100%; width: 100%; background-color: rgba(0,0,0,0.3);}
  background: no-repeat center rgba(238, 239, 238, 1); background-size:cover;
  .container { }
  .slide { position: relative; top: 0; left: 61px; padding-right: 15px; }
  h1 { font-size: 4.3rem; font-weight: $font-weight-semi; line-height: 1.35; background: url($image-url + "royaltri-branding-print-web-marketing-montreal-forest-bkgnd.jpg") center no-repeat $body-color; -webkit-background-clip: text; -webkit-text-fill-color: transparent; margin-bottom:0; padding: 3.15rem 0; padding-right: 15px;
    @include mQ($medium-desktop) {font-size: 3.9rem; }
    @include mQ($tablet) {font-size: 3.8rem; }
    @include mQ($mobile) { }
    &:before { content: ''; position: absolute; left: 0; top: -6px; height: 6px; width: 200px; background: url($image-url + "royaltri-branding-print-web-marketing-montreal-forest-bkgnd.jpg") center no-repeat $body-color; background-attachment: fixed; }
    }
  .custom-btn { margin-top:0;

    &:hover:not([disabled]), &:hover:not(.loading){
      &:after { background-color: #eeefee;  }
      }
    }

  .typed-cursor {
      opacity: 1;
      animation: blink 1500ms step-end infinite;
      font-size:0;
      &:before { content: ''; width: 1px; height: 4.4rem; background-color: #111; display: inline-block; margin-left: 3px; vertical-align: top; margin-top: 10px; }
      }
  &.no-overlay {
    &:before { background-color: transparent; }
    }
  }

.home {
  .home-slider {
    &:before { opacity:0; background-color: transparent; }
    }
  }

@media(max-width: $medium-desktop) {
  .home-slider { min-height:598px; }
  }

@media(max-width: $tablet) {
  .home-slider { min-height: 497px;
    .container { }
    h1 { font-size: 3.4rem;
      &:before { height: 5px; top: -5px; }
      }
    .slide { left: 52px; }
    .typed-cursor {
      &:before { height: 3.6rem; }
      }
    }
  .home .home-slider  { min-height:0; }
  }

@media(max-width: $mobile) {
  .home-slider { min-height: 403px;
    .container { }
    h1 { font-size: 2.8rem;
      &:before { height: 4px; top: -4px; width: 147px; }
      }
    .slide { left: 40px; }
    .typed-cursor {
      &:before { height: 3rem; }
      }
    }
  }

@media(max-width: $mobile-xs) {
  .home-slider { padding: 9.5rem 0 5rem;
    h1 { font-size: 2.7rem;
      &:before { width: 115px; }
      }
    .typed-cursor {
      &:before { height: 2.8rem; margin-top:5px; }
      }
    }
  }


@media(max-width: $mobile-xxs) {
  .home-slider {
    h1 { font-size: 2.2rem;
      &:before { height: 3px; top: -3px; }
      }
    .typed-cursor {
      &:before { height: 2.2rem; }
      }
    }
  }

@media(max-width: 430px) {
  .home-slider {
    h1 { font-size: 2.25rem; }
    .typed-cursor {
      &:before { height: 2.25rem; }
      }
    }
  }

@media(max-width: 390px) {
  .home-slider {
    h1 { font-size: 1.7rem; }
    .typed-cursor {
      &:before { height: 1.7rem; }
      }
    }
  }

@media(max-width: 375px) {
  .home-slider {
    h1 {
      &:before { height: 2px; margin-top: -2px; width: 100px; }
      }
    }
  }

@media(max-width: 360px) {
  .home-slider {
    h1 { font-size: 1.7rem;
      &:before { height: 2px; margin-top: -2px; width: 95px; }
      }
    .typed-cursor {
      &:before { height: 1.7rem; }
      }
    }
  }

@media(max-width: 335px) {
  .home-slider {
    h1 { font-size: 1.55rem;
      &:before { height: 2px; margin-top: -2px; width: 95px; }
      }
    .typed-cursor {
      &:before { height: 1.55rem; }
      }
    }
  }

#typed { white-space: pre; background: url($image-url + "royaltri-branding-print-web-marketing-montreal-forest-bkgnd.jpg") center no-repeat $body-color; -webkit-background-clip: text; -webkit-text-fill-color: transparent; }

@keyframes blink {
    0% {opacity: 0}
    50% {opacity: 1}
    to {opacity: 0}
}

/*Bonjour*/
.bonjour {
  color: $white; position: relative; @extend %section;
  &:after { content: ''; position: absolute; width: 100%; left: 0; right: 0; bottom: 0; top: 0; background: rgba(0, 0, 0, 0.65); }
  .container { position: relative; z-index: 20; }
  h2, p { font-size: $font-size-base + 0.3; transition: 0.5s all linear; /*transform: translateY(50px);*/ }
  h2 { font-weight: $font-weight-bold; padding-left: 60px; }
  p { margin-bottom: 0; line-height: 1.4; font-weight: $font-weight-light; padding-right: 60px;
    strong { font-weight: $font-weight-semi; }
    }
  .bg-slider { position: absolute; top: 0; bottom: 0; left: 0; z-index: 0; width: 107%; background: url($image-url + 'royaltri-branding-web-design-marketing-agency-montreal.jpg') no-repeat center; background-size: cover; transition: left .35s linear; }
  }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .bonjour {
      .bg-slider { .slide { background-image : url($image-url + 'royaltri-branding-web-design-marketing-agency-montreal@2x.jpg'); } }
      }
}

@media(max-width: $tablet) {
  .bonjour {
    h2, p { font-size: $font-size-base + 0.2;}
    h2 { padding-left: 44px; }
    p { padding-right: 44px; }
    }
  }

@media(max-width: $mobile) {
  .bonjour {
    h2, p { padding-left: 44px; }
    p { padding-right: 0; }
    }
  }

@media(max-width: $mobile-xs) {
  .bonjour {
    h2, p { padding-left: 40px; padding-right: 40px; }
    }
  }

/*Services*/
.services {
  @extend %section;
  .row { margin-left:-30px; margin-right:-30px; }
  /*h3 { margin-top: $headings-margin-top; }*/
  .service-list {
    .service-box {
      padding-left:30px; padding-right:30px; padding-top: 5.65rem;
      h4 { margin-bottom: 1.25rem; margin-right:1.5rem; min-height : 100px; line-height: 1.4;
        @include mQ($tablet) { min-height: 75px; }
        }
      h6 { text-transform: uppercase; font-weight: 300; margin-bottom:0.7rem; }
      p { margin-bottom: 2.2rem; @include mQ($mobile) { margin-bottom:2rem; } }
      .img-holder { min-height: 95px; margin-bottom: 1.55rem;
        img { height: 5.3rem; width: auto; }
        }
      }
    .custom-btn { margin-top:0; }
    }
  }

@media(max-width: $tablet) {
  .services {
    .row { margin-left: -15px; margin-right: -15px; }
    .service-list {
      .service-box { padding-left: 15px; padding-right:15px; padding-top: 4rem; }
      }
    }
  }

@media(max-width: $mobile) {
  .services {
    .row { margin-left:-15px; margin-right:-15px; }
    .service-list {
      .service-box {
        .img-holder { margin-bottom:1.3rem; min-height:0; }
        h4 { margin-right:0; min-height: 0; }
        }
      }
    }
  }

.portfolio {
  @extend %section; color: $white; position: relative;
  @include mQ($tablet) { padding-top: 5.7rem; }
  .bg-container { position: absolute; top: 0; bottom: 0; left: 0; right: 0; z-index: 0;
    .bg { position: absolute; top: 0; bottom: 0; left: 0; right: 0; opacity: 0; transition: 0.15s opacity linear;
      &:first-child { opacity:1 !important; }
      }
    }
  .block-wrapper { position: relative; z-index: 20; }
  .portfolio-block {
    &:last-child {padding-bottom: 0;}
    .container { position: relative; display: flex; align-items: center;
      &:after { content: ""; display: table; clear: both; }
      }
    @extend %section;
    position: relative; @include mQ($mobile-xs) {padding: 4rem 0;}
      .portfolio-content , .portfolio-img { font-size:0; vertical-align: top; }
      .portfolio-content                  { flex:0 0 35%; margin-left: auto;
        @include mQ($tablet) {flex: 0 0 43%;}
        .portfolio-content-block {
          }
        h6 , h4 { font-weight: $font-weight-base; text-transform: uppercase; margin-top:0; }
        h4 { margin-bottom: 2.4rem; }
        h6 {line-height: 1.4; margin-bottom: 1.9rem;}
        p{ font-weight:$font-weight-light; }
        .custom-btn { margin-top:0; }
        }
      .portfolio-img { flex:0 0 55%;  margin-right: auto;
        @include mQ($tablet) {flex: 0 0 50%;}
        .device { background: url($image-url + "royaltri-branding-web-design-marketing-agency-montreal-desktop.svg") no-repeat; background-size:100%; width:100%; padding: 3.7% 4% 19.7%;
          .screen { position: relative; transition: 0.7s all linear; width:100%; padding-bottom: 57.7%; overflow: hidden; background-color: $white;
            img { width: 100%; position: absolute; top: 0; left: 0; transition: top 1.5s cubic-bezier(.694,0,.335,1); }
            }
          }
        &.aos-animate img {
          &.no-animation { animation: none; }
          }
        }
    &.even .portfolio-content { margin-right: auto; margin-left: 0; }
    &.even .portfolio-img     { margin-left: auto; margin-right:0; }
    &.lewindsor { }
    &.monsieur  { }
    &.absolute-left .portfolio-img {
      img , .no-animation { animation: none; }
      }
    &.absolute-right .portfolio-img {
      img { animation: none; }
      }

    &:after { content: ''; display: table; clear: both; }
    &.light {
      h6, h4, p { color: $body-color; }
      }
    }
    h3 { color: $white;
    &:before {
      border-color: $white;
      }
    }
  .load-more {
    .custom-btn {margin-top:5.5rem;}
    }
  }

/*@media(max-width: $medium-desktop) {
  .portfolio {
    .portfolio-block {
      .portfolio-img {
        .device { height: 390px; padding: 18px 18px 0;
          .screen { height: 271px; }
          }
        }
      }
    }
  }

@media(max-width: $medium-desktop) {
  .portfolio {
    .portfolio-block {
      .portfolio-content {
        .portfolio-content-block { height: auto; }
        }
      }
    }
  }

@media(max-width: $tablet) {
  .portfolio {
    .portfolio-block {
      .portfolio-img {
        .device { height: 263px; padding: 13px 13px 0;
          .screen { height: 182px; }
          }
        }
      }
    }
  }

@media(max-width: $mobile) {
  .portfolio {
    .portfolio-block {
      .portfolio-img {
        .device { height: 196px; padding: 9px 9px 0;
          .screen { height: 136px; }
          }
        }
      }
    }
  }*/

@media(max-width: $mobile-xs) {
  .portfolio {
    .portfolio-block {
      .container { display: table; table-layout: fixed; }
      .portfolio-img, .portfolio-content { width: auto; flex: auto; }
      .portfolio-content { display: table-footer-group; text-align: center;
        .portfolio-content-block { padding-top:2rem;
          h4 { margin-bottom: 1.8rem; }
          h6 { margin-bottom: 1.4rem; }
          .custom-btn { /*margin-top: 10px;*/ }
          }
        }
      .portfolio-img { display: table-header-group;
        /*.device { height: auto; width: 100%; padding: 3.7% 4% 19.8%;
          .screen { height: auto; padding-bottom: 57.5%; position: relative;
            img { position: absolute; top:0; left:0; animation: none; }
            }
          }*/
        }
      }
    }
  }

/*@keyframes scrollScreenShort {
  0%, 2%, 100% { transform: translate3d(0, 0, 0) }
  30% { transform: translate3d(0, -25%, 0) }
  50% { transform: translate3d(0, calc(-50% + 100px), 0) }
  65% { transform: translate3d(0, calc(-75% + 334px), 0) }
  85%, 90% { transform: translate3d(0, calc(-100% + 334px), 0) }
  }*/

.heroshot {
  padding: 3.35rem 0; position: relative; z-index: 25;
  p { margin: 0; padding: 0.8rem 0; text-transform: uppercase; font-weight: $font-weight-semi; line-height: 1.6; font-size: $font-size-base; }
  .custom-btn, .btn-group { display: inline-block; vertical-align: top; margin-top: 0; float: none; }
  .custom-btn { @include mQ($tablet) { float: none; width: auto; } }
  .btn-group { margin-right: 15px; }
  &.grey {
    background-color: #cccccc;
    color: $body-color;
    .custom-btn { background-color: $body-color; color: $white;
      span {
        svg { stroke: $white; fill: $white; }
        }
      &:hover { color: $body-color;
        span {
          svg { fill: $body-color; stroke: $body-color; }
          }
        }

      &.custom-btn-primary { background-color: transparent; color: $body-color; border-color: $body-color;
        &:after { background-color: rgba(0, 0, 0, 1); }
        span {
          svg { fill: $body-color; stroke: $body-color; }
          }
        &:hover {
          span { color: $white; border-color: $white;
            a { color: $white; opacity:1; }
            svg { fill: $white; stroke: $white; }
            }
          }
        }

      &.custom-btn-dark {
        &:hover:not([disabled]), &:hover:not(.loading) {
          &:after { background-color: rgba(204, 204, 204, 1); }
          }
        }

      &.custom-btn-white {
        &:hover:not([disabled]), &:hover:not(.loading) {
          &:after { background-color: #cccccc; }
          }
        }

      }
    }

  &.black {
    background-color: $body-color;
    color: $white;
    .custom-btn { background-color: $white; color: $body-color;
      &:after { background-color: rgba(0,0,0,1); }
      span {
        svg { stroke: $body-color; fill: $body-color; }
        }
      &:hover { color: $white;
        span { svg { fill: $white; stroke: $white; } }
        }
      &.custom-btn-primary { background-color: transparent; border-color: $white; color: $white;
        &:after { background-color: rgba(255,255,255,1); }
        svg { stroke: $white; fill: $white; }
        &:hover span {
          color: $white; border-color: $white;
          svg { stroke: $white; fill: $white; }
          }
        }

      /*&.custom-btn-ghost {
        background-color: transparent;
        &:after { background-color: $white; }
        span {
          &.link {
            a { color: $white; }
            }
          }
        span {
          &.arrow {
            &:before { border-color: $white; }
            }
          }
        }*/

      }
    }
  }



@media (max-width: $medium-desktop) {
  .heroshot {
    p { padding:0; }
    .btn-group { margin-right:20px; }
    }
  }

@media (max-width: $tablet) {
  .heroshot { text-align: center;
    p { margin-bottom: 30px; }
    }
  .two-btn-heroshot {
    .btn-group { margin-left:0; margin-right: 15px;
      .custom-btn { /*padding-left: 1rem; padding-right: 2.8rem;*/ }
      }
    .custom-btn {  }
    }
  }

@media (max-width: $mobile) {
  .heroshot {
    p { margin-bottom: 20px; }
    }
  }

@media (max-width: 370px) {
  .heroshot {
    .btn-group { margin-bottom: 15px; margin-right: 15px; }
    .btn-group, .custom-btn { /*margin-right: 15px; margin-left: 15px;*/ }
    }
  }


.btn-group {
    &.slided, &:hover {
      .custom-btn:after { height: 430%; opacity: 1; z-index: 0; }
      span { color: $white;
        a { color: $white; }
        }
      .arrow { &:before{ border-color: $white; } }
      }

   .arrow { position: absolute; right: 1rem; top: 0; z-index: 100; cursor: pointer; height: 100%; width: 20px;
     @include mQ($tablet) { right:0.9rem; }
     svg { width:100%; height: 100%; fill: $body-color; top:-1px; }
     &:before { content: ''; border-right: 2px solid $body-color; border-bottom: 2px solid $body-color; width: 13px; height: 13px; transform: translateY(-50%) rotate(45deg); top: 50%; left: 3px; position: absolute; margin-top: -4px;
       @include mQ($tablet) { width: 11px; height: 11px; margin-top: -3px; left: 4px; }
       @include mQ($mobile) { width: 10px; height: 10px; }
       }
     /*&:hover { border-color: $white; }*/
     }

  .custom-btn { cursor: default; text-align: left; padding:0;
    &.custom-btn-ghost {
      &:hover {
          .custom-btn:after { opacity: 1; z-index: 0; }
          span { color: $body-color; }
          .arrow { border-color: $body-color; }
        }
      }
     span {
       &.link {
         a {
           padding: .75rem 3rem .75rem 1.5rem;
           }
         }
       }
    }
  .dropdown-menu { display: none; position: absolute; top:100%; width: 100%; background-color: $white; z-index: 100; font-size:$font-size-base + 0.1; font-weight: $font-weight-semi; border:2px solid $body-color; border-top-width: 0; margin-top:0;
    .dropdown-item { display: block; line-height:2; text-align: center; width: 100%; color: $body-color; padding: .25rem 0; opacity:1; text-transform: uppercase;
      &:before { height: 0; }
      &:active, &:focus { background-color: transparent; }
      &:hover { background-color: #f3f3f3; }
      }
    &:hover ~ .custom-btn { background-color: $body-color; }
    }
}

.testimonials {
  .testimonial-container { position: relative; }
  @extend %section; position: relative;
  background: url($image-url + "royaltri-branding-web-design-marketing-agency-montreal-keyboard.jpg") top right no-repeat #FEFEFE; background-size: 450px 838px;
  .coffee-mug { position: absolute; width: 250px; height: 250px; right: -65px; top: 40px; background: url($image-url + "royaltri-branding-web-design-marketing-agency-montreal-coffee.png"); background-size: cover; }
  .smoke { z-index: 20; position: absolute; width: 308px; height: 240px; background: url( $image-url + 'coffee-steam.png') no-repeat; right: -5px; bottom: 0; margin-left: 0px; transition: 0.5s transform linear; opacity:0.8; }
  }

.testimonials-carousel { margin-left: 5rem; position: relative; padding-top: 5.6rem;
    &:before { content: open-quote; font-weight: 800; color: #e8e8e8; font-size: 12.2rem; position: absolute; top: -25px; left: -75px; }
    p {}
    h6 { margin-bottom: 0; font-style: italic; font-weight: $font-weight-normal; text-transform: uppercase; line-height: 1.8; position: relative; padding-left:22px; padding-right: 20px;
      @include mQ($tablet) { padding-left: 20px; }
      @include mQ($mobile) { font-size:1rem; padding-left:18px; padding-right: 18px; }
      &:before { content: "\2014 \00A0";  position: absolute; left: 0; top: -2px; }
      span { display: block; }
      }
    .carousel-arrows { margin-top: 1.875rem;
      .prev, .next { @extend %nav-arrow; text-align: center; overflow: hidden; display: inline-block; margin-right: 8px; cursor: pointer; background: transparent; width: 38px; height: 38px; border: 2px solid #E8E8E8; border-radius: 50%; vertical-align: middle; transform: perspective(1px) translateZ(0); box-shadow: 0 0 1px transparent; position: relative; transition-property: color; transition-duration: 0.5s;
        svg { fill: $gray-700; transition: 0.5s fill linear; text-align: center; width: 12px; height: 22px; margin-top: 6px; margin-left: -3px; }
        &:before { content: ""; position: absolute; z-index: -1; top: 0; left: 0; right: 0; bottom: 0; background: $body-color; border-radius: 100%; transform: scale(0); transition: 0.5s transform ease-out; }
        &:after { border-right: 2px solid #5a5a5a; border-bottom: 2px solid #5a5a5a; width: 16px; height: 16px; top: 9px; transition: 0.5s border ease-out; }
        &:hover svg { fill: #ffffff; }
        &:hover:before { transform: scale(2); }
        &:hover:after { border-color: $white; }
        }
      .prev {
        &:after {
          right: 6px;
          transform: rotate(135deg);
          }
        }
      .next {
        &:after { left: 6px;
          transform: rotate(-45deg);
          }
        svg { margin-left: 3px; }
        }
      }
    }


@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .testimonials { background-image: url($image-url + 'royaltri-branding-web-design-marketing-agency-montreal-keyboard@2x.jpg');
    .coffee-mug { background-image: url($image-url + 'royaltri-branding-web-design-marketing-agency-montreal-coffee@2x.png'); }
    }
}

@keyframes rotate-smoke {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
  }

 @keyframes show {
    0% { opacity: 0; transform: translate3D(0, 50px, 0) }
    to { opacity: 1; transform: none }
    }

.clients { padding-top: 6.7rem; @include mQ($tablet) { paddding-top: 5.7rem; }
  ul { width:100%; padding:0; margin: 0; font-size:0; text-align: center; display: flex; justify-content: space-between; align-content: space-between;
    li { list-style: none; display: inline-block; width: 105px; vertical-align: middle; text-align: center; margin-top:3.2rem;
      &.line-break { width: 100%; display: none; margin-top:0 !important; }
      a { display: table-cell; height: 105px; vertical-align: middle; opacity: 1;
        &:before { height: 0; }
        img { display: block; animation-duration: 0.5s; max-width: 105px; max-height: 100%; backface-visibility: hidden;
          &.show { animation: show 0.25s both; }
          }
        }
      }
    }
  }

@media(max-width: $medium-desktop) {
  .clients {
    ul {
      li { width: 90px;
        a { height: 90px;
          img {max-width: 90px;}
          }
        }
      }
    }
  }

@media(max-width: $tablet) {
  .testimonials {
    .coffee-mug { width: 200px; height: 200px; right: -55px; top: 370px; }
    }
  .clients {
    ul {
      li { width: 85px;
        a { height: 85px;
          img { max-width: 85px; }
          }
        &:last-child { display: none; }
        }
      }
    }
  .testimonials-carousel {
    &:before { font-size: 11rem; top: -10px; left: -55px; }
    }
  }

@media(max-width: $mobile) {
  .testimonials { background-size: 400px 745px;
    .smoke { display: none; opacity:0; }
    .coffee-mug { width: 180px; height: 180px; right: -75px; top: auto; bottom: -12px; }
    }

  .testimonials-carousel { margin-left: 4rem; margin-right: 4rem; padding-top: 4.6rem;
      &:before { top: -21px; }
    .carousel-arrows {
      .prev, .next { width: 32px; height: 32px;
        &:after { width: 12px; height: 12px; top: 8px; }
        }
      }
      }

  .clients {
    ul {  flex-flow: row wrap;
      li {
        &.line-break-sm { display: block; }
        &:last-child { display: inline-block; }
        }
      }
    }
  }

@media(max-width: $mobile-xs) {
  .testimonials { background-size: 300px 559px;
    .coffee-mug { width: 160px; height: 160px; right: -30px; }
    }
  .clients {
    ul { justify-content: space-around; align-content: space-around; }
    }
  }

@media(max-width: 535px) {
  .clients {
    ul {
      li { margin-bottom:0;
        &.line-break-xs { display: block; }
        &.line-break-sm { display: none;}
        }
      }
    }
  }

.clients-carousel { margin: 0 -50px;
  @include media-breakpoint-down(lg) { margin: 0 -40px; }
  @include media-breakpoint-down(md) { margin: 0 -35px; }
  @include media-breakpoint-down(sm) { margin: 0 -50px; }
  @media (max-width: 400px) { margin: 0 -30px; }
  .our-clients {
    .slick-slide { display: inline-block; vertical-align: middle; float: none;
      .logo { padding: 0 50px;
        @include media-breakpoint-down(lg) { padding: 0 40px; }
        @include media-breakpoint-down(md) { padding: 0 35px; }
        @include media-breakpoint-down(sm) { padding: 0 50px; }
        @media (max-width: 400px) { padding: 0 30px; }
        img { max-width: 100%; max-height: 75px;
          @media (max-width: 400px) {  }
        }
      }
    }
  }
}


.hear-from-client {

  }

.news {
  @extend %section;
  background: #fafafa;
  .news-row { margin:0 -10px; margin-bottom:20px;
    &:last-child { margin-bottom: 0; }
    &:after { content: ''; clear: both; display: table;}
    }
  .news-list { padding-top:$section-padding-top; color: $white; text-transform: uppercase;
    .news-block { display: block; position: relative; height: 330px; background: top center no-repeat; overflow: hidden; background-size:cover; opacity:1;
      @include mQ($medium-desktop){ height: 269px; }
      @include mQ($tablet){ height: 199px; }
      @include mQ($mobile){ height: 400px ; }
      @include mQ($mobile-xs){ height: 350px ; }
      @media(max-width: 400px){ height: 280px ; }
      &:hover .content-wrapper { transform: translateZ(-100px); opacity: 1; }
      .news-images { position: absolute; left:0; right:0; top:0; bottom:0; transform-style: preserve-3d;
        .news-image { position: absolute; top: 0; left: 0; bottom: 0; right: 0; background-size: cover; background-position: center center; background-repeat: no-repeat;
          .news-img { position: absolute; top: 0; left: 0; bottom: 0; right: 0; max-width: 100%; height:100%; width: 100%; object-fit: cover;
              @include mQ($mobile-xs) {height: 100%; object-fit: cover;}
            }
          }
        .parallex-0 {transform:translateZ(-1px);}
        .parallex-30 {transform:translateZ(30px);}
        .parallex-60 {transform:translateZ(60px);}
        }
      .content-wrapper { position: absolute; top: 30px; transform: translateZ(50px); transition:0.5s transform linear; padding: 0 30px 30px; }
      .date { margin-bottom: 1rem; width: 100%; }
      .title { margin-bottom: 0; }
      .date , .title { display: none; position: relative; text-shadow: 0 0 6px #000; }
      &:before { content: ''; position: absolute; top:0; bottom:0; left:0; right:0; width:100%; height:100%; background-color: rgba(0,0,0,0.2); }
      .tags { position: absolute; right:65px; top:50%; transform: translateY(-50%);
          p { margin-bottom:0; line-height:1.3; font-size:$font-size-base+0.2; }
        }
      }
    a { color: $white; }
    .block { position: relative; padding:0 10px; margin-bottom: 20px;
      &:last-child { @include mQ($mobile) {margin-bottom: 0;} }
      }
    .big-block { width: 65%; float: left;}
    .small-block { width: 35%; float: right;
      .content-wrapper { top: auto; bottom:30px; padding:30px 30px 0; }
      }
    .even {
      .big-block { float: right;}
      .small-block { float:left;}
      }
    }

  .custom-btn { margin-top: 5.5rem; }

  &.news-listing {
    h3 { display: inline-block; }
    }
  }

@media (max-width: $tablet) {
  .news {
    .news-list {
      .news-block {
        .content-wrapper { padding:0 20px 20px; top: 20px; }
        }
      .small-block {
        .content-wrapper { padding: 20px 20px 0; bottom: 20px; top: auto; }
        }
      }
    }
  }

@media(max-width: $mobile) {
  .news {
    .news-row { margin-bottom:0; }
    .news-list {
      .big-block,.small-block { float: none; width: 100%; margin-bottom:20px; height:auto; }
      .news-block {
        .content-wrapper { position: static; padding: 15px; }
        }
      }
    }
  }

.news-details { @extend %section;
  .date { font-size: 0.875rem; font-weight: $font-weight-semi; }
  .container { }
  .content { padding: 5.65rem 8% 0;
    @include mQ($mobile) { padding-right: 0; padding-left: 0; }
    h3 { margin-bottom: 1.85rem; }
    li { padding-bottom: 10px; font-size: 0.9rem; line-height: 1.9; }
    .figure { margin: 3.125rem 0;
      img { margin-bottom: 0; }
    }
  }
  /*.share-icons { margin-top: 1.85rem; }*/
}

.contact-us {
  font-size:1.4rem;  position: relative;
  .contact-wrapper { position: relative; background-color: $white; @extend %section; }
    h4 { font-weight:$font-weight-base; font-size:1.4rem; }
    .contact-form { padding-top: $section-padding-top;
      @include mQ($mobile) {padding-top: 5rem;}
      @include mQ($mobile-xs) {padding-top: 4.5rem;}
      .form-group { position: relative; }
      textarea , .form-control { background-color: transparent; border:none; width:100%; outline:none !important; box-shadow: 0 0 0 transparent !important; font-size:1.4rem; color: $body-color; padding:0;
        &:focus .blink { opacity:0; }
        }
      textarea { resize: none; min-height:340px;
        @include mQ($mobile) {min-height: 300px;}
        @include mQ($mobile-xs) {min-height: 280px;}
        }
      .blink { animation: blink 1s infinite; position: absolute; top: 32px; left: -2px; font-size: 1.95rem; font-weight: 300; font-family: serif; color: $black; }
      }
  }

.map-section { min-height:450px;
  @include mQ($tablet) { min-height: 400px; }
  @include mQ($mobile) { min-height: 350px; }
  @include mQ($mobile-xs) { min-height: 325px; }
  #map-content {
    a {
      &:before { height: 0; }
      }
    }
  }

.wrapper { background: $white; z-index: 15; overflow: hidden; position: relative; transition: .3s opacity; }

.main-footer { padding-top: $section-padding-top; color: $white; bottom: 0; z-index: 10; position: fixed; left: 0; width: 100%; opacity: 0;  transition: .3s opacity;
  @include mQ($tablet) { padding-top: 5.7rem; }
  .footer-container { position: relative;
    .footer-left { float: left; position: relative;
      .hello {
        h2 { font-weight: $font-weight-base; margin-bottom: 0.8rem; }
        p { font-size: $font-size-base + 0.2; line-height: 1.5; margin-bottom: 0;
          span { font-size: $font-size-base + 0.2; }
          a { color: $white; opacity: 1;
            &:before { height:0; }
            }
          }
        }
      }

    .footer-nav { float: right;
      &.site-nav {
        .vertical-menu {
          li { opacity: 1; transform: translateZ(0); }
          }
        .horizontal-menu { margin-top: 30px; margin-bottom: 30px; @include mQ($mobile) {margin:25px 0;} }
        .social-icons { margin-bottom: 80px; }
        }
      }

    &:after { content: ''; display: table; clear: both; }

    .newsletter { position: absolute; bottom:88px; left:0; width: 47%; @include mQ($tablet) { width: 53%; }
        label { font-size: $font-size-lg - 0.25; display: block; margin-bottom: 10px; line-height:0.7; cursor: pointer; position: relative; top:10px;
          span { min-width:1.2rem; display: inline-block; vertical-align: top; font-size: 1.2rem; position: relative; top:-1px; }
          &.active {
            span { top:-3px; }
            }
          }
        .newsletter-form { display: none; margin-top: 1.4rem; margin-left:0.94rem;
          input[type="text"],input[type="email"] { width: 100%; }
          button { display: none;
            }
          }
        }
    }

  .footer-bottom { position: relative; border-top: 1px solid #fbfbfb; padding: 1.2rem 0;
    a { opacity:1; }
    .copyright { float: left; text-transform: uppercase;
      p { font-size: $font-size-base - 0.15; line-height: 33px;
        @include mQ($tablet) { font-size: $font-size-base; line-height:29px; }
        @include mQ($mobile) { line-height:25px;} }
        /*span { @include mQ($mobile) { float: left; } }*/
      }
    .website-by { float: right;
      @include media-breakpoint-down(xs) { margin-top: 20px; }
      a { opacity: 0.8;
        &:hover { opacity: 1; }
        &:before { height:0; }
        }
      svg { fill: $white; width: 2rem; height: 2rem; vertical-align: middle; }
      }
    p { font-size: $font-size-base; margin: 0; line-height: 2.8; }
    }
  }

@media (max-width: $mobile) {
  .wrapper { margin-bottom: 0 !important; }
  .main-footer { position: relative; bottom: auto; left: auto;
    .footer-container {
      .footer-nav.site-nav {
        .social-icons { margin-bottom:30px; }
        }
      .newsletter {
        clear: both; position: relative; bottom: 12px; width:100%; margin-bottom: 6rem;
        label { font-size: $font-size-lg - 0.25; }
        input[type="text"] { width: 100%; }
        }
      }
    }
  }

@media (max-width: $mobile-xs) {
  .main-footer {
    .footer-container {
      .footer-left , .footer-nav { float: none; text-align: right; }
      .site-nav { margin-top:30px;
       ul, li { /*text-align: left;*/ }
        }
      }
    }
  }

.before-after-container { background: url( $image-url + "royaltri-branding-web-design-marketing-agency-montreal-desktop.svg") center top no-repeat; background-size: contain; position: relative; width: 65.5%; padding: 2.4% 2.7% 13%; margin: 3.125rem auto;
	/*@include media-breakpoint-down(md) { padding: 18px 20px 0; height: 377px; width: 493px; }*/
	@include media-breakpoint-down(sm) { width: 100%; padding: 3.7% 4.1% 20%; }
	.screen { position: relative; width: 100%; overflow: hidden; background-color: #FFF;
	}

	.twentytwenty-before-label:before, .twentytwenty-after-label:before { font-size: 0.6rem; padding: 0 8px; line-height: 25px; }

}

.share-icons {
  a { opacity:1; margin-right: 0 !important; margin-left: 0 !important;
    @media (max-width: 400px) { padding-left: 2px !important; padding-right: 2px !important; }
  }
}

.inner-page-content {
  @extend %section;
  p { margin-bottom: 1.1rem; }
  h6 { line-height: 1.4;
    @include mQ($mobile) { line-height: 1.5; }
    /*br {
      @include mQ($mobile-xs) { display: none; }
      }*/
    }
  h4,h6 { margin-bottom:1.2rem; font-weight:$font-weight-normal; text-transform: uppercase; }
  h4 {
    @include mQ($mobile) { margin-top: 1.4rem; }
    }
  .share-icons { text-align: right; float: right;
    @include mQ($mobile) {
      margin-top: 1.85rem;
      float: none;
      text-align: left;
      }
    h5 { margin-bottom: 0.8rem; }
    }
  .content { /*@include mQ($tablet) {padding-top:0;}*/ }
  .img-caption {}
  }


.work-description { @extend %section;
  .work-description-content { padding: 0 8%;
    @include mQ($mobile) { padding: 5.7rem 0 0 0; }
    .excerpt {
      p { padding-right: 4.166665%;
        &:last-child { margin-bottom:0; @include mQ($mobile) {margin-bottom: 1.1rem;} }
        }
      .column { padding: 0 30px;
        &.first {padding-left: 0}
        &.last {padding-right: 0}
        }
      }
    .content { padding-right: 4.5rem;
      @include mQ($mobile) {
        padding-right:0;
        }
      }
    }
  }

.work-details {
  h1 { margin-bottom: 6.7rem; @include mQ($tablet) { margin-bottom: 5.7rem; } }
  h4 { font-weight:$font-weight-normal; text-transform: uppercase;
    br {
      @include mQ($tablet) { display: none;} }
    }
  .work-grids { display: table; width: 100%;  position: relative; clear: both;
    &:after { content: ''; display: table; clear: both; }
    .work-grid-column { width: 50%; position: relative; display: table-cell; vertical-align: middle;
      &:nth-child(even) {
      .content {}
      }
      }
    .content { padding: 6.7rem 3.5rem; position: relative; z-index:10; @include mQ($mobile) {} }
    .img-holder { position: absolute; top: 0; bottom: 0; left: 0; right: 0; width: 100%; height: 100%; background: transparent no-repeat center; background-size: cover;
      /*img { width: 100%; height: 100%; }*/
      video { position: absolute; top: 0; bottom: 0; right: 0; left: 0; width: 100%; height: 100%; }
      &.background-size-auto { background-size: auto !important; }
      &.background-size-100 { background-size: 100% !important; }
      &.background-size-contain { background-size: contain !important; }
      }
    .img-holder-box { padding-bottom: 90.07%; width: 100% }
    &.two-columns {
      .content-wrap { display: inline-table; }
      .img-holder { background: no-repeat center center; background-size: cover; /*width: 50%;*/ position: absolute; top: 0; bottom: 0; }
      &:nth-child(odd) {
        .content-wrap {
          .content { }
          }
        .img-holder:nth-child(1) { left: 0; }
        .img-holder:nth-child(2) { right: 0; }
        .background-size-auto {background-position: left center !important; }
        }
      &:nth-child(even) {
        .content-wrap {
          .content { }
         }
        .img-holder:nth-child(1) { right: 0 }
        .img-holder:nth-child(2) { left: 0 }
        .background-size-auto {background-position: right center !important; }
        }
      }
    &.three-columns {

      }

    &.absolute-top-right {
      .content {position: absolute; top:0; right:0; width: 41.66667%;}
    }
    &.absolute-top-left { .content { position: absolute; top:0; left:0; width: 41.66667%; }  }

    &.full-column {
      .container { position: relative; }
      .img-holder-box { }
      }
    &.four-columns {
      .img-holder-box { padding-bottom:82%; }
      }
    &.one-third-columns {
      .img-holder-box { padding-bottom:22.5%; }
      }
    &.four-one { /*min-height:550px;*/
      .img-holder-box {  padding-bottom:82%;
        &.background-size-auto { background-size: auto;
          }
        }
      .content-wrap { display: table; }

      &:nth-child(odd) {
        .img-holder {
          &.background-size-auto { background-position: right bottom; }
        }
        .content-wrap {
          .content { padding-left:0; }
          }
        .column:nth-child(1) { left: 0; }
        .column:nth-child(2) { right: 0; }
      }

      &:nth-child(even) {
      .img-holder {
        &.background-size-auto { background-position: left bottom; }
        }
        .content-wrap {
          .content { padding-right:0; }
         }
        .column:nth-child(1) { right: 0 }
        .column:nth-child(2) { left: 0 }
      }

      }
    p { margin-bottom: 1.4rem;
      &:last-of-type {margin-bottom:0}
      }
    .custom-btn { margin-top: 1.8rem; }

    &.big-background { background-repeat: no-repeat; background-image: url('/assets/img/le-windsor/royaltri-branding-marketing-agency-montreal-lewindsor-web-design.jpg'); background-position: left bottom; padding-bottom: 76%; background-size:cover;
      @include mQ($tablet) { padding-bottom:100%; }
      @include mQ($mobile) { padding-bottom:200%; }

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
          background-image: url('/assets/img/le-windsor/royaltri-branding-marketing-agency-montreal-lewindsor-web-design@2x.jpg');
        }
      }

    .rsImg { max-width: 100%; }

    .content-full-width { padding-left: 8%; padding-right: 8%; padding-bottom:6.7rem; @include
        mQ($tablet) { padding-left:0; padding-right:0; padding-bottom:5.7rem }
    }

	  .rsCustom .rsArrow { display: none !important; }
	  .rsCustom .rsBullets { bottom: 50px; }

    }

  #ourWorkCarousel { padding-top: 0; padding-bottom: 6.7rem; @include mQ($tablet) { padding-bottom: 5.7rem; }
    .carousel-cell { counter-increment: carousel-cell; margin: 0; }
    img { width: auto;
      @include mQ($tablet) { height: 140px; }
      @include mQ($mobile) { height: 120px; }
      @include mQ($mobile-xs) { height: 100px; }
      }
    }

  .work-grid-column{
      .container-half { padding-left:63px; margin-right: 0;
        @include mQ($mobile) { padding-left:15px; padding-right: 15px; }
        @include mQ($mobile-xs) { padding-left:20px; padding-right: 20px; }
        .content { padding-left:0; }
        }
    }

  .work-grid-column {
    &:nth-child(even) {
      .container-half {padding-right:15px; margin-left: 0;
      @include mQ($mobile) { padding-left:15px; }
      @include mQ($mobile-xs) { padding-left:20px; }
        .content { padding-right:0; }
        }
      }
    }

  .dynamic-user-experience { @extend %section; background: no-repeat center; min-height: 720px; overflow: hidden;
    &.lbb { background-color: #fafafa;
      .container { position: relative; }
      .dishes { position: absolute; width: 100%; top: 0; bottom:0; left:0; right:0; height:100%; min-height:670px;
        @include mQ($tablet) { min-height:620px; }
        .dish { position: absolute; transition: 0.8s transform linear , 1.4s opacity linear; opacity:1;
          img {transition: 0.8s transform linear; }
          &.chicken-plate { width: 32.2rem; height: 32.2rem; left: -160px; top: -190px;  transform: translate(-150px, -150px);
            @include mQ($medium-desktop) { width: 31.2rem; height: 31.2rem; }
            img { transform: rotate(60deg); } }
          &.fried-chicken { width: 16.25rem; height: 14rem; right: -202px; top: -197px; transform: translate(100px, -100px);
            @include mQ($mobile) { right: -225px; top: 0; }
            @include mQ($mobile-xs) { right: -125px; }
            img { transform: rotate(-35deg); } }
          &.water-melon { width: 17.2rem; height: 17rem; bottom: -15px; left: -156px; transform: translate(-100px,100px);
            @include mQ($medium-desktop) { width: 15.2rem; height: 15rem; }
            @include mQ($tablet) { width: 14.2rem; height: 14rem; }
            @include mQ($mobile) { width: 12rem; height: 11.8rem; }
            }
          &.sauces { width: 14.5rem; height: 16.4rem; left: 360px; top: -125px;
            @include mQ($medium-desktop) { width: 13.5rem; height:15.4rem; left: 325px; }
            @include mQ($mobile) { left: 385px; top: -35px; }
            }
          &.cocktail { width: 13.5rem; height: 12.3rem; left: 455px; top: 25px;
            @include mQ($medium-desktop) {width: 11.5rem; height: 11.3rem; left: 375px; top: 75px;}
            @include mQ($tablet) { width: 10.5rem; height: 10.3rem; left: 235px; top: 140px; }
            @include mQ($mobile) { left: 245px; top: 5px; }
            }
          &.oysters-popcorn { width: 28.25rem; height: 28.25rem; bottom: -65px; left: 200px; transform: translate(100px, 250px);
            @include mQ($medium-desktop) { width: 27.25rem; height: 27.25rem; left:150px;  }
            @include mQ($tablet) { width: 26.25rem; height: 26.25rem; left: 60px; }
            @include mQ($mobile) { left: 10px; }
            img {transform: rotate(-60deg);}
            }
          &.veuve-clicquot { width: 30.3rem; height: 34.4rem; right: -185px; bottom: -100px; transform : translate(200px,200px);
            @include mQ($medium-desktop) { width: 29.3rem; height: 33.4rem; bottom: -135px; }
            @include mQ($tablet) { width: 28.3rem; height: 32.4rem; }
            @include mQ($mobile) { width: 27.3rem; height: 31.4rem; }
            }
          &.bubbles { width: 8.5rem; height: 8.5rem; right: 320px; bottom: 190px;
            @include mQ($medium-desktop) { right: 250px; bottom: 180px; }
            @include mQ($tablet) { right: 175px; bottom: 160px; }
            @include mQ($mobile) { width: 7.5rem; height: 7.5rem; right: 105px;  }
            }
          &.animate { transform: translate(0,0);
            img { transform: rotate(0deg); }
            }
          }
        }
      }
    }
  }

.muliple-img { background-color: #FAFAFA; @extend %section;
  .full-column { display: block; margin-top: 10px;
  &:first-child { margin-top: 0; }
  }
  .two-columns {
    margin-top: 30px;
  }
  .col-sm-6 {
    &:nth-child(-n+2) {
      .two-columns {margin-top: 0;}
    }
  }

}

@media(max-width: $medium-desktop) {
  .work-details {
     .work-grids{
       &.ipad-contain {
        .work-grid-column {
          .img-holder { background-size: contain; }
        }
      }
     }
    }
  }

@media(max-width: $mobile) {
  .work-details {
    h1 { margin-bottom: 1.85rem; }
    .work-grids{
      &.two-columns { display: block;
        .work-grid-column { width: 100%; display: block;
          .container-half { max-width: 540px; margin: 0 auto; }
            /*&:nth-child(even) {
              .content { padding-top:0; }
            }
            &:nth-child(odd) {
              .content { padding-bottom:0; }
            }*/
          }
        .content-wrap { min-height: 0;
          .content { padding: 5.7rem 0; }
          }
        &.ipad-contain {
          .work-grid-column {
            .img-holder { background-size: cover; }
            }
          }

        &.ipad-contain {
          .content-wrap {
            .content {padding-bottom: 0;}
            }
          }
        }
      &.four-columns {
        .img-holder {   min-height: 240px; }
        }
      &.four-one {
        .column , .content-wrap { position: static; width:100%; display: block; min-height:0;
          .img-holder { /*min-height: 500px;*/ }
          }
          .column {
            &.full-box {
              .img-holder { min-height: 500px; }
              }
            }
          .content { min-height:0; padding: 4.4rem 0; }
        }
      }

    .dynamic-user-experience { padding: 0 !important; min-height: 0;
      .container { padding: 18rem 0 22rem; }
      &.lbb {
        .dishes {
          .dish { transform: translate(0, 0) !important;
            img { transform: rotate(0deg) !important; }
            }
          }
        }
      }
    }
  }

@media(max-width: $mobile-xs) {
  .work-details {
    .work-grids{
      &.four-columns {
        .img-holder { min-height: 200px; }
        }
      }
    }
  }

@media(max-width: $mobile-xs) {
  .work-details {
    .dynamic-user-experience.lbb {
      .dishes {
        .dish {
          &.oysters-popcorn { left: 85px; }
          &.bubbles { right: 85px; }
          &.water-melon { left:-65px; }
          }
        }
      }
    }
  }


@media(max-width: 530px) {
  .work-details {
    .dynamic-user-experience.lbb {
      .dishes {
        .dish {
          &.oysters-popcorn { width: 24.25rem; height: 24.25rem; }
          &.bubbles { right: 85px; }
          &.water-melon { left:-65px; width: 10rem; height: 9.8rem; }
          &.veuve-clicquot { width: 25.3rem; height: 29.4rem; right: -160px; }
          }
        }
      }
    }
  }


@media(max-width: 510px) {
  .work-details {
    .dynamic-user-experience.lbb {
      .dishes {
        .dish {
          &.cocktail { left: 215px; top: 5px; }
          &.sauces { top: -35px; left: 350px; }
          &.fried-chicken { right: -125px; }

          &.oysters-popcorn {left: 32px; }
          &.water-melon { bottom:100px; }
          }
        }
      }
    }
  }

@media(max-width: 480px) {
  .work-details {
    .dynamic-user-experience.lbb {
      .dishes {
        .dish {
          &.chicken-plate { width: 30.2rem; height: 30.2rem }
          &.cocktail { left: 185px; }
          }
        }
      }
    }
  }


@media(max-width: 465px) {
  .work-details {
    .dynamic-user-experience.lbb {
      .dishes {
        .dish {
          &.chicken-plate { width: 30.2rem; height: 30.2rem }
          &.cocktail { width: 9.5rem; height: 9.3rem; }
          &.sauces { width: 10rem; height: 11.4rem; }
          &.fried-chicken { width: 14.25rem; height: 12rem; }
          &.oysters-popcorn { width: 22.25rem; height: 22.25rem; left:18px; }
          &.water-melon { bottom:90px; }
          &.bubbles { right: 90px; bottom: 135px; }
          }
        }
      }
    }
  }


@media(max-width: 430px) {
  .work-details {
    .dynamic-user-experience.lbb {
      .dishes {
        .dish {
          &.sauces { left: 320px; }
          &.fried-chicken { width: 14.25rem; height: 12rem; }
          &.oysters-popcorn { width: 21rem; height: 21rem; left: -12px; }
          &.water-melon { width: 9rem; height: 8.8rem; bottom: 155px; left: -35px; }
          &.bubbles { right: 75px; }
          }
        }
      }
    }
  }

@media(max-width: 375px) {
  .work-details {
    .dynamic-user-experience.lbb {
      .dishes {
        .dish {
          &.cocktail { top: 80px; left: 155px; }
          &.sauces { left: 265px; }
          &.oysters-popcorn { width: 19rem; height: 19rem; left: -12px; }
          &.water-melon { width: 9rem; height: 8.8rem; bottom: 155px; left: -35px; }
          &.bubbles { right: 90px; }
          &.veuve-clicquot { width: 23.3rem; height: 27.4rem; right: -155px; bottom: -83px; }
          }
        }
      }
    }
  }

@media(max-width: 359px) {
  .work-details {
    .dynamic-user-experience.lbb {
      .dishes {
        .dish {
          &.water-melon { bottom: 145px; }
          &.bubbles { right: 60px; }
          &.veuve-clicquot { width: 21.3rem; height: 25.4rem; }
          }
        }
      }
    }
  }

@media(max-width: 359px) {
  .work-details {
    .dynamic-user-experience.lbb {
      .dishes {
        .dish {
          &.chicken-plate { width: 29.2rem; height: 29.2rem; }
          &.fried-chicken { top: 15px; }
          &.cocktail { left: 140px; }
          &.sauces { left: 250px; }
          }
        }
      }
    }
  }


.full-width-slider {
  width: 100%;
  color: #000;
  text-align: center;

  .rsImg { /*width: 100%; max-width: 100%;*/ }

  }

@media screen and (min-width:960px) and (min-height:660px) {
  .heroSlider .rsOverflow,
  .royalSlider.heroSlider {
      height: 520px !important;
  }
}

@media screen and (min-width:960px) and (min-height:1000px) {
    .heroSlider .rsOverflow,
    .royalSlider.heroSlider {
        height: 660px !important;
    }
}
@media screen and (min-width: 0px) and (max-width: 800px) {
  .royalSlider.heroSlider,
  .royalSlider.heroSlider .rsOverflow {
    height: 300px !important;
  }
  .infoBlock {
    padding: 10px;
    height: auto;
    max-height: 100%;
    min-width: 40%;
    left: 5px;
    top: 5px;
    right: auto;
    font-size: 12px;
  }
  .infoBlock h3 {
     font-size: 14px;
     line-height: 17px;
  }
}


.full-website { @extend %section; background-color: #DFDFDF; background-size: 50%; position: relative;
  &:after { content: ''; display: table; clear: both; }
  .container { position: relative; padding: 0 120px; @include mQ($tablet) { padding: 0 105px; }  }
  .top-bar { background-color: #d6d6d6; border-radius: 6px 6px 0 0; height:28px; width: 100%; position: absolute; top:0; left:0; padding:9px 10px;
      span { vertical-align: top; background-color: #333333; border-radius: 50%; border: 1px solid #d6d6d6; width: 10px; height:10px; display: inline-block; margin-right:3px; }
      &.dark {background-color: #333333;
        span { background-color: #f4f4f4; border-color: #151515; }}
      }
  .desktop-browser { padding-top:28px; position: relative;
    img.desktop-img { width: 100%; border:1px solid #e8e8e8; border-top: none; }
    }
  .mobile { width: 211px; position: absolute; margin-left:-105px; top: 108px; overflow: hidden;
    @include mQ($medium-desktop) { margin-left:-105px; }
    @include mQ($tablet) { width: 180px; margin-left: -88px; }
    &:before { width: 100%; height: 100%; content: ""; position: absolute; z-index: 20; background: no-repeat; background-size: cover; }
    .img-holder { background-color: #e6e6e6; overflow: hidden; position: absolute;
      img { width: 100%; }
     }
    &.iphone { height: 435px; @include mQ($tablet) {height: 371px;}
      .img-holder { margin:53px 13px 0; border-radius: 0; height: 333px;
        @include mQ($tablet) { margin:46px 11px 0; height: 283px; } }
      &:before { background-image: url($image-url + "royaltri-branding-web-design-marketing-agency-montreal-iphone.svg"); }
      }
    &.samsung { height: 473px;
      @include mQ($tablet) { height: 403px; }
      .img-holder { margin: 21px 0 0 0; border-radius: 10px; height:435px;
        @include mQ($tablet) { margin:18px 0 0 0; height: 371px; }
        }
      &:before { background-image: url($image-url + "royaltri-branding-web-design-marketing-agency-montreal-s8.svg"); }
      }
    }
  }

@media (max-width: $mobile) {
  .desktop-website { display: none; }
  }

.mobile-website { text-align: center; display: none; @include mQ($mobile) { display: block; }
  .navigation { padding-bottom: 3.35rem;
    ul { list-style: none; margin: 0; padding: 0; font-size: 0; text-align: center;
      li { cursor: pointer; -webkit-transition: all 0.5s; transition: all 0.5s; text-transform: uppercase; position: relative; overflow: hidden; display: inline-block; font-size: 1rem; font-weight: 600; min-width: 13.1rem; border: 2px solid $body-color; background: $white; padding: .75rem .75rem; line-height: 1.5; border-radius: 0; text-align: center; margin-top: 1.4rem;
        @include mQ($mobile) {min-width: 10.5rem;}
        &:last-child { border-right: 2px solid $body-color; }
        &.active { background-color: $body-color; color: $white; }
        }
      }
    }
  .screenshot {
    .screenshot-box { display: none;
        &:first-child { display: block; }
      img { width: 100%; }
      .top-bar { text-align: left; position: static; }
      }
      }
    }


.featured-block { @extend %section;
  .container { max-width:740px;
    @include mQ($tablet) { max-width: 540px; }
    }
  h4 { font-weight: $font-weight-semi; }
  .featured-image { position: relative;
    img { width: 100%; }
    .photo-caption { width: 200px; position: absolute;
        p,h6 { font-size: 0.75rem; margin: 0; line-height: 26px; }
        h6 { font-weight:$font-weight-semi; }
      &.left { left:-225px; text-align: right; }
      &.right { right:-225px; text-align: left; }
      }
    }
  .share-icons {
    .tags { text-align: right; font-size:0;  padding:0; margin: 0; list-style: none;
      li { font-size:1rem; display: inline-block; margin-left: 15px; margin-bottom:10px; text-transform: uppercase;
        &:first-child { margin-left:0; }
        &:last-child { margin-bottom:0; }
        a { color: $body-color; }
        }
      }
    }
  }

.previous-next {
  display: table; width: 100%;
  .linked-page { display: table-cell; vertical-align: middle; width: 50%; position: relative; background-color: $body-color; padding: 3rem 0; overflow: hidden;
    .content { position: relative; z-index: 20; text-shadow:2px 2px 4px #000; @include mQ($mobile-xs) { padding-top:30px; }
      h4, p { margin: 0; text-transform: uppercase; }
      h4 { @include mQ($mobile-xs) { font-size:1.3rem;} }
      p { @include mQ($mobile-xs) { font-size:0.8rem;} }
      &:before { content: ''; opacity:0.7; transition: 0.5s opacity ease-out; position: absolute; top: 50%; transform: translateY(-50%); border-radius: 50%; background: no-repeat 42% 50% $body-color; background-size: 38%; width: 38px; height: 38px; border: 2px solid $body-color;
        @include mQ($mobile) { width: 30px; height: 30px; background-size: 38%; }
        @include mQ($mobile-xs) { top: 5px; }
        }
      }
    a { text-decoration: none;
      &:before { &:hover { height:0; } }
      &:hover { text-decoration: none; }
      }
    h4 { font-weight: $font-weight-normal; }
    a.project-link, .project-image { position: absolute; top: 0; left: 0; right: 0; bottom: 0; }
    a.project-link { z-index: 25;
      &:before { height: 0; opacity:0; }
      }
    .project-image { background: no-repeat center; background-size: cover; transition: transform 2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      &:before {content: ''; background-color: rgba(0,0,0,0.25); position: absolute; top:0; bottom:0; left:0; right:0; }
      }
     &.next, &.prev {
        .content {
          &:before { background-color: $white; }
          }
        }
    &.next {
      .container-half {margin-left:0;}
      .content { text-align: right; padding-right: 75px;
        @include mQ($mobile) {padding-right:55px;}
        @include mQ($mobile-xs) { padding-right:20px; padding-left:20px; }
        &:before { background-image: url($image-url + 'next-arrow.svg'); right:15px; background-position: 54% 50%;
          @include mQ($mobile-xs) {right: 20px;}
          }
        }
      }
    &.prev {
      .container-half {margin-right:0;}
      .content { padding-left: 75px;
        @include mQ($mobile) {padding-left:55px;}
        @include mQ($mobile-xs) {padding-left:20px;  padding-right:20px; }
        &:before { background-image: url($image-url + 'prev-arrow.svg'); left:15px;
          @include mQ($mobile-xs) {left: 20px;}
          }
        }
      }
    &.dark {
      color: $white; background-color: #FAFAFA;
      h4, p { color: $white; }
      &.next, &.prev {
        .content {
          &:before { background-color: $body-color; }
          }
        }
      &.prev {
        .content {
          &:before { background-image: url($image-url + 'prev-white-arrow.svg'); border-color: $white; }
          }
        }
      &.next {
        .content {
          &:before { background-image: url($image-url + 'next-white-arrow.svg'); border-color: $white; }
          }
        }
      }

    &:hover {
      .project-image { transform: scale(1.08); }
      .content { &:before { opacity:1; } }
      &:before {
        &:hover { height : 0; }
        }
      }
    }
  }

.big-form { position: relative;
  .form-group { margin-bottom:0; position: relative; }
  input[type="text"] , input[type="email"] { border-radius: 0; padding-bottom: 10px; position: relative; outline: none !important; background-color: transparent; border: none; border-bottom: 2px solid $white; font-size: $font-size-lg + 0.5; color: $white; padding-right: 35px;
    @include mQ($tablet) { padding-bottom: 8px; }
    }
  button { cursor: pointer; outline: none !important; border: 0; position: absolute; right: 0; background: transparent; width: 14px; height: 26px; background-size: cover; bottom: 17px;
    @include mQ($mobile) { bottom: 6px;}
    &:after { content: ''; border-top: 3px solid $white; border-right: 3px solid $white; position: absolute; width: 18px; height: 18px; top: 2px; -webkit-transition: 0.3s border ease-out; transition: 0.6s border ease-out; transform: rotate(45deg); left: -7px;
      @include mQ($tablet) { width: 16px; height: 16px; top:7px; }
      @include mQ($mobile) { width: 14px; height: 14px; top:1px; }
      }
    &:hover:after { border-color: $white; }
    }
  }

.contact-msg { margin-top: 1.4rem; }
.error-message , .success-message { font-size:$font-size-base - 0.1; font-weight:$font-weight-normal; color: $error-color; line-height: 1.45;
  @include mQ($mobile) { font-size: $font-size-base; } }
.success-message { color: #28a745; }

.no-gutters {
  margin-right: 0;
  margin-left: 0;
  > .col, > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: $tablet) {
  .big-form {
    input[type="text"] { font-size: $font-size-lg + 0.3; }
    }
  }

@media (max-width: $mobile-xs) {
  .container { padding-right: ($grid-gutter-width / 2) + 5 !important; padding-left: ($grid-gutter-width / 2) + 5 !important; }
  }

.hire-us-form { @extend %section; position: relative; opacity: 1; color: $white; transition: opacity 0.71s cubic-bezier(0.23, 1, 0.32, 1);
  .container { position: relative; z-index: 20; }
  label { font-size: 1.4rem; display: block; margin-bottom: 10px; line-height: 0.7; text-transform: uppercase; color: $white; }
  .fs-form-overview {
    label { font-size: 1.2rem; }
    }
  input, textarea { width: 100%; border: none; padding: 10px 0; background-color: transparent; color: #ffffff; font-weight: 400; font-size: 2.4rem; resize: none; border-bottom: 2px solid #ffffff; border-radius: 0; }
  .fs-form-overview  {
    input, textarea  { font-size: 1.8rem; }
    }
  textarea { height: 200px; }
  .fs-continue { position: absolute; right: 65px; top: 50%; margin: 0; z-index: 20; background: transparent; border: 0; cursor: pointer; font-size: 0; width: 26px; height: 26px; transform: translateY(-50%); margin-top: 25px; z-index:1001;
    &:after { content: ''; border-top: 3px solid #fff; border-right: 3px solid #fff; position: absolute; width: 18px; height: 18px; top: 4px; -webkit-transition: 0.3s border ease-out; -webkit-transition: 0.6s border ease-out; transition: 0.6s border ease-out; -webkit-transform: rotate(45deg); transform: rotate(45deg); left: -7px; }
    }
  .fs-form-overview {
    label { }
    }
  }

.hire-us {
  .main-header {
    .logo {
      svg { fill: $white; }
      }
    .search {
      a {
        svg { fill: $white; }
        }
      }

     &.clone { display: none;
       .logo {
      svg { fill: $body-color; }
      }
    .search {
      a {
        svg { fill: $body-color; }
        }
      }
      }
    }
  .menu-hamburger {
    .menu-hamburger-box {
      &:before, &:after { background-color: $white; }
      }
    }
  .main-footer { display: none; }
  .wrapper { margin-bottom: 0 !important; background-color: transparent; }
  }

.dropdown-menu {
  &.datepicker { padding: 0.5rem 0.8rem; border-radius: 2px; margin-top: 0.725rem;
    table { width: 100%;
      tr {
        td, th { width: 36px; height: 32px; border-radius: 0; font-size: 0.85rem;
          @include mQ($tablet) { font-size: 1rem; }
          @include mQ($mobile) { width: 32px; height: 28px; }
          span {
            &.active , &.focused {background: $body-color !important; color: $white; }
            }
          &.day {
                &.focused, &:hover { background-color: rgba(0,0,0,0.8); color: $white; }
              }
          &.active {background: $body-color !important; }
          }
        }
      }
    .datepicker-switch , .next, .prev ,tfoot tr th { padding: 10px 0;
      &:hover { background-color: rgba(0,0,0,0.8); color: $white; }
      }
    }
  }

.no-padding-top     { padding-top: 0; }
.no-padding-bottom  { padding-bottom: 0; }

.hash-section { position: relative;
  .hash-target { position: absolute; width: 0; height: 0; top: 1px;}
  }

.hire-us-section {

  input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px transparent inset;
      -webkit-text-fill-color: $body-color !important;
    }

  .first-table{ width: 100%; height: 100%;
    th { height: 248px; vertical-align: bottom;
      @include mQ($tablet) { height: 227px; }
      }
    td { vertical-align: middle; }
    }

  h3 { color: $white; margin-bottom: 3rem; padding-bottom: 0; line-height: 1.5;
    &:before { display: none; }
  }

  h4 { margin-bottom: 2.4rem; color: $white; }

  &:after { content: ''; display: table; clear:both; }
  .form-sent-message { display: none; color: $white; z-index: 100; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); text-align: center; width: 100%; padding: 3.35rem 0;
      h1 { color: $white; backface-visibility: hidden; }
      p { font-size: 1.25rem; padding:0 20px; backface-visibility: hidden; }
      }

  &.thanks {
    .fullpage-nav, #fullpage { display: none; }
    .form-sent-message {display: block; }
    }

  .custom-container { max-width : 720px; position: relative; height: 100vh;
    @include mQ($tablet) { max-width: 620px; }
    @include mQ($mobile) { max-width: 540px; }
    }
  .hire-form {@include mQ($mobile) {margin-right: 30px;} }
  .section { padding: 0; background-size: cover; background-attachment: fixed; }

  .form-group { position: relative;
    label {
      &.required {
        &:after { content: ''; position: absolute; top: -5px; right: 0; width: 18px; height: 18px; background: url($image-url + 'abacusstar.svg') no-repeat center; background-size: contain;
          @include mQ($tablet) { width: 15px; height: 15px; }
          @include mQ($mobile) { width: 13px; height: 13px; top: 0; }
          }
        }
      }
    .credit-cards { position: absolute; bottom: 25px; right: 0;
      @include mQ($tablet) { bottom: 20px; }
      @include mQ($mobile) { bottom: 16px; }
      @include mQ(550px) { bottom: -33px; }
      ul { margin: 0; padding: 0; list-style: none; font-size: 0;
		  li { width: 50px; height: 32px; display: inline-block; background: no-repeat center; background-size: cover; opacity: 0.5; margin-right: 5px; border-radius: 3px;
          @include mQ($mobile) { width: 40px; height: 26px; }
          &.amex {background-image: url($image-url + 'royaltri-branding-marketing-agency-montreal-amex.svg')}
          &.visa {background-image: url($image-url + 'royaltri-branding-marketing-agency-montreal-visa.svg')}
          &.master {background-image: url($image-url + 'royaltri-branding-marketing-agency-montreal-mastercard.svg')}
          &.active { opacity: 1; box-shadow: 0 0 3px #ffffff; }
          &:last-child { margin-right: 0; }
        }
      }
    }
    .row > .col-sm-6 {
      @include mQ($mobile-xs ) { -webkit-box-flex: 0; -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
    }

    }
  label { font-size: 1.25rem; display: block; margin-bottom: 10px; line-height: 0.7; text-transform: uppercase; color: #fff; position: relative;
    @include mQ($mobile) { font-size: 1.2rem; line-height: normal; }
    &.error { color: $error-color; font-weight: 600; font-size: 0.95em; padding-top: 1rem; text-transform: none; line-height: 1.3; position: absolute; top: 100%; left: auto;
      @include mQ($tablet) { bottom: -50px; }
      @include mQ($mobile) { bottom: -45px; }
      }
    }
  .form-control { width: 100%; border: none; padding: 10px 0; padding-right:35px; background-color: transparent; color: $white; font-weight: 400; font-size: 2rem; resize: none; border-bottom: 2px solid #ffffff; border-radius: 0; box-shadow: 0 0 0 transparent; background-color: transparent;
    @include mQ($mobile) { font-size: 1.8rem; }
    @include mQ($mobile-xs) {
      @include inputPlaceholder() { font-size:1.5rem; }
      }
    &#deadline {
      @include inputPlaceholder() { font-size:2rem; }
    }

    @include mQ(365px) {
      @include inputPlaceholder() { font-size:1.36rem; }
      }

    &:focus { background-color: transparent; box-shadow: 0 0 0 transparent; border-color: $white; color: $white; }
    &[readonly] { background-color: transparent; }
    /*&[required] { background-image: url($image-url + 'abacusstar.svg'); background-position: top right; background-size: 18px; background-repeat: no-repeat;
      @include mQ($mobile) { background-size: 13px; }
      }*/
    &[type="number"] { -webkit-appearance: none; background-image: url($image-url + 'royaltri-montreal-agency-dollar.svg'); padding-left: 1.1em; background-position: left 20px; background-size: 30px; background-repeat: no-repeat;
      @include mQ($tablet) { background-position: 0 21px; }
      @include mQ($mobile) { background-position: 0 18px; background-size:21px; }

      &.no-currency { background-image: none; padding-left: 0; }
      }

    &[type="number"]::-webkit-inner-spin-button, &[type="number"]::-webkit-outer-spin-button { -webkit-appearance: none; }
    &.cc { background: none; padding-left: 0; }
    }
  textarea { min-height: 200px; }

  .multiple-fields { position: relative;
    .form-group { margin-bottom: 4rem;
      .col-sm-8, .col-md-4 { position: static; }
      &:last-of-type { margin-bottom: 0; }
      }
    }


  .hire-us-btn { position: absolute; right: 0; bottom: 12px; margin: 0; z-index: 20; background: transparent; border: 0; cursor: pointer; font-size: 0; width: 26px; height: 26px; -webkit-transform: translateY(-50%); transform: translateY(-50%); margin-top: 6px;
    @include mQ($mobile) { margin-top: 0; width: 23px; height: 23px; bottom: 0; width: 21px; }
    &:after { content: ''; border-top: 3px solid #fff; border-right: 3px solid #fff; position: absolute; width: 18px; height: 18px; top: 4px; transition: 0.6s border ease-out; -webkit-transform: rotate(45deg); transform: rotate(45deg); left: 0; backface-visibility: hidden;
      @include mQ($mobile) { width: 15px; height: 15px; top:-2px; }
      @include mQ(375px) { width: 15px; height: 15px; }
      }
    }

  .helper {
    &:before { position: absolute; bottom: 100%; left: 0; line-height: 20px; padding: 0 0 10px; min-width: 200px; content: attr(data-info); font-size: 0.7rem; text-transform: none; color: rgba(255, 255, 255, 0.9); opacity: 0; -webkit-transition: opacity 0.3s, -webkit-transform 0.3s; transition: opacity 0.3s, transform 0.3s; -webkit-transform: translate3d(0, -5px, 0); transform: translate3d(0, -5px, 0); pointer-events: none; }
    &:hover:before { opacity: 1; -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }
    &:after { position: relative; margin-top: -3px; display: inline-block; margin-left: 10px; width: 20px; height: 20px; border: 2px solid rgba(255, 255, 255, 0.9); color: rgba(255, 255, 255, 0.9); border-radius: 50%; content: 'i'; vertical-align: top; text-align: center; font-weight: 700; font-style: italic; font-size: 12px; font-family: Georgia, serif; line-height: 18px; cursor: pointer; pointer-events: auto; text-transform: lowercase;
      @include mQ($tablet) { margin-top: -5px; }
      @include mQ($mobile) { width: 17px; height: 17px; font-size: 10px; line-height: 15px; }
      }
   }

  .helper-icon { position: relative; margin-top: -3px; display: inline-block; margin-left: 10px; width: 20px; height: 20px; border: 2px solid rgba(255, 255, 255, 0.9); color: rgba(255, 255, 255, 0.9); border-radius: 50%; content: 'i'; vertical-align: top; text-align: center; font-weight: 700; font-style: italic; font-size: 12px; font-family: Georgia, serif; line-height: 18px; cursor: pointer; pointer-events: auto;
    &:before { position: absolute; bottom: 100%; left: 0; line-height: 20px; padding: 0 0 10px; min-width: 200px; content: attr(data-info); font-size: 0.7rem; text-transform: none; color: rgba(255, 255, 255, 0.9); opacity: 0; -webkit-transition: opacity 0.3s, -webkit-transform 0.3s; transition: opacity 0.3s, transform 0.3s; -webkit-transform: translate3d(0, -5px, 0); transform: translate3d(0, -5px, 0); pointer-events: none; }
    &:hover:before { opacity: 1; -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }
    }

  .radio-group { font-size:0;
    span { display: inline-block; position: relative; width: 25%; text-align: center; font-weight: 700;
      @include mQ(510px) {width: 50%;}
      input[type="radio"] { position: absolute; display: block; margin: 0 auto 20px; width: 100%; height: 100%; opacity: 0; cursor: pointer; }
      label { display: block; padding-top: 145px; width: 100%; height: 100%; cursor: pointer; opacity: 0.45; color: #ffffff; transition: 0.15s opacity linear; font-size: 14px; font-weight: 400; margin: 0 0 -10px; backface-visibility: hidden;
        @include mQ($mobile) { padding-top: 120px; }
        @include mQ(510px) { padding-top: 110px; margin-bottom: 10px; }
        &:after { position: absolute; top: 50%; left: 50%; width: 85px; height: 85px; background-position: center; background-size: contain; background-repeat: no-repeat; content: ''; -webkit-transform: translate(-50%,-50%); transform: translate(-50%,-50%);
          @include mQ($tablet) { width: 75px; height: 75px; }
          @include mQ($mobile) { width: 60px; height: 60px; }
          }
        }
      label.branding {
        &:after{ background-image: url($image-url + 'royaltri-branding-web-design-marketing-agency-montreal-hire-us-brand-identity.svg'); }
        }
      label.website {
        &:after{ background-image: url($image-url + 'royaltri-branding-web-design-marketing-agency-montreal-hire-us-website.svg'); }
        }
      label.marketing {
        &:after{ background-image: url($image-url + 'royaltri-branding-web-design-marketing-agency-montreal-hire-us-strategies.svg'); }
        }
      label.everything {
        &:after{ background-image: url($image-url + 'royaltri-branding-web-design-marketing-agency-montreal-services-all.svg'); }
        }
      &:hover { }
      }
      label{
        &:hover { opacity:1; }
        }

    .radio-group label:hover,
    input[type="radio"]:focus + label,
    input[type="radio"]:checked + label { opacity:1; }
    }
  .send-btn { display: none; }

  .fp-section { opacity: 0; visibility: hidden; }
  .fp-section.active, .fp-section.fp-completely { opacity: 1; visibility: visible; }
  .section { display: none; }

  &.review { @extend %section;
    .first-table {
      th { display: none; }
      }
    h3 , h4 { display: none; }
    .custom-container { height: auto; }
    .fullpage-nav { display: none; }
    .section { margin: 2rem 0; padding-bottom: 2rem;
      &:first-child {margin-top: 0;}
      }
    label { font-size:1.2rem;
      &.error { font-size:0.9rem; }
      }
    .form-control { font-size: 1.8rem;
      &[type="number"] { background-position:0 20px; background-size:26px;
        @include mQ($tablet) { background-position: 0 17px; background-size: 24px; }
        @include mQ($mobile) { background-position: 0 15px; background-size: 20px; }
        }
      }
    .hire-form { padding-top: 5rem; @include mQ($mobile) { margin-right: 0; } }
    .hire-us-btn { display: none; }
    .section { display: block; }
    .send-btn { display: block; float: right; background-color: transparent; }
    }

  .StripeElement { border-bottom: 2px solid $white !important; padding: 10px 0;
    &.brand { position: relative;
      &:after { content: '';position: absolute;right: 0;top: 50%;width: 50px; background: url( $image-url + 'royaltri-branding-marketing-agency-montreal-blank-cc.svg') no-repeat center;background-size: cover;height: 32px;transform: translateY(-50%); }
      &.brand-visa {
        &:after { background-image: url($image-url + 'royaltri-branding-marketing-agency-montreal-visa.svg'); }
      }
      &.brand-mastercard {
        &:after { background-image: url($image-url + 'royaltri-branding-marketing-agency-montreal-mastercard.svg'); }
      }
      &.brand-amex {
        &:after { background-image: url($image-url + 'royaltri-branding-marketing-agency-montreal-amex.svg'); }
      }
    }
  }

  .StripeElement--invalid {
    &:after {background-image: url($image-url + 'royaltri-branding-marketing-agency-montreal-blank-cc.svg') !important; }
  }

  }

.fr .hire-us-section .form-control[type="number"] { padding-left: 0; background-position: 94% 18px; }

.fullpage-nav { position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%); z-index:100;
  ul { margin: 0; padding: 0; list-style: none; text-align: right; position: absolute; right:15px; top: 50%; transform: translateY(-50%); }
  li { background-color: $black; overflow: hidden; cursor: pointer; position: relative; display: block; padding: 0; margin: 14px 0; width: 14px; height: 14px; border: 1px solid #fff; border-radius: 50%; transition: transform 0.3s ease, opacity 0.3s ease;
    &.disabled { cursor: auto; }
    @include mQ($mobile) {width: 11px; height: 11px;}
    &:before { content: ""; position: absolute; z-index: -1; top: 0; left: 0; right: 0; bottom: 0; background-color: $white; border-radius: 100%; transform: scale(2); transition: 0.5s transform ease-out, 0.3s background-color linear; }
    &.active , &:hover { background-color: $white;
      &:before { background-color: $white; }
      }
    &.disabled { background-color: rgba(255,255,255,0.2);
      &:before {transform: scale(0);}
      }
    }
  }

.hire-us{
  #fp-nav, .fp-slidesNav {
    ul {
      li {
        a {
          &:before { height: 0; }
          &:after { }
          span { background: $white; }
          }
        }
      }
    }
  }

.services-page {
  .wrapper { background: transparent; }
}

.services-section { @extend %section; color: $white;
  @include mQ($mobile) { background-attachment: scroll; }
  &:first-of-type { padding-top: calc(6.7rem + 76px); }
  .container { padding: 0 76px;
    @include mQ($tablet) { padding: 0 15px; }
    @include mQ($mobile) { padding: 0 55px; }
    }
  a { color: $white;
    &:before { background-color: $white; }
    &:hover { color: $white; }
    }
  h1, h3 {color: $white;
    &:before {border-color: $white; }
    }
  h6, h4 { font-weight: $font-weight-normal; text-transform: uppercase; }
    p { font-size: 1rem; line-height: 30px; margin-bottom: 1.1rem; font-weight:300;
      @include mQ($mobile) { font-weight:400; }
      &:last-of-type { margin-bottom: 0; }
      }
  .service-details { @extend %section;
    h6 { line-height: 1.5; }
    .services-offered {
      h6 { font-weight: $font-weight-semi; margin-bottom:0.9rem; padding-top: 2.5rem; clear: both;
        &:first-child { padding-top: 3.25rem; }
        }
      ul { font-size:0; margin: 0; padding:0; float: left; list-style: none; width: 44%; margin-right: 6%; }
      li { font-size: 1rem; padding-bottom: 0.75rem; font-weight: 300; @include mQ($mobile) { font-weight:400; } }
      p { padding-top: 1.75rem; margin-bottom: 0; clear: both; }
      }
    }

  .clients-section {
    h6 { @include mQ($tablet) { padding-left:47px; }  @include mQ($mobile) { padding-left:0; }  @include mQ($mobile-xs) { margin-left: 4rem; } }
    .testimonials-carousel { padding-top: 0; margin-left: 4.5rem; @include mQ($tablet) { padding-right:47px; } @include mQ($mobile) { padding-right:0; }
      @include mQ($mobile) { margin-left:0; margin-right: 0; }
      @include mQ($mobile-xs) { margin-left:4rem; margin-right: 4rem; }
      &:before { top: -7.1625rem; color: rgba(255, 255, 255, 0.7);
        @include mQ($tablet) { top: -90px; left: -60px; }
        @include mQ($mobile) { top: -105px; left: -50px; }
        }
      }
    .testimonials-carousel h6 { margin-top: 1.875rem; margin-bottom: 0;
      @include mQ($mobile-xs) { margin-left:0; }
      }
    .testimonials-carousel p { font-size:0.9rem; line-height: 1.9; }
    .testimonials-carousel {
      .carousel-arrows {margin-top: 1.875rem;
        .prev, .next { border-color: #777;
          &:before { background-color: #2f2f2f; }
          &:after { border-color: $white; }
          }
        }
      }

    .clients { /*padding-top: 6.7rem;*/ padding-left:4.5rem; @include mQ($tablet) { padding-top: 5.7rem; padding-left:0; }
      ul { flex-flow: row wrap;
        li { /*margin: 50px 0 0;*/
          &.line-break { width: 100%; }
          &.line-break-sm { display:block;
            @include mQ($tablet) {display:none;}
            @include mQ($mobile) {display:block;}
            @include mQ(535px) { display:none; }
            }
          &.line-break-xs { display: none;  @include mQ(535px) { display:block; } }
          &:nth-last-of-type(-n+2) { @include mQ(535px) { margin-bottom: 0; } }
          &:last-child { display: inline-block; @include mQ($tablet) { display: none;} @include mQ($mobile) { display: inline-block; } }
          a { height: 115px;
            @include mQ($tablet) { height: 90px;}
            }
          }
        }
      }
    }

    .cms-logos { list-style: none; padding: 0; margin: 0 -20px 0;
      figure.services-offered-img { display: inline-block; padding: 15px 20px 0; margin: 20px 0 0;
        img { height: 35px; }
      }
      figure.ionos {
        img { height: 70px !important; }
      }
      a { opacity: 1; }
      a:before { content: none !important; }
      a:hover { opacity: .7; }
    }
  }

.our-clients { @extend %section; background-color: $white;
  .client-logos {
    .logo-block { text-align: center; padding-top: 100px;
      @include media-breakpoint-down(md) { padding-top: 75px; }
      @include media-breakpoint-down(xs) { padding-top: 60px; }
      @media (max-width: 425px) { padding-top: 50px; }
      //@media (max-width: 375px) { padding-top: 35px; }
      .logo { padding: 0 60px;
        @include media-breakpoint-down(md) { padding: 0 40px; }
        @include media-breakpoint-down(sm) { padding: 0 15px; }
        @include media-breakpoint-down(xs) { padding: 0 50px; }
        @media (max-width: 450px) { padding: 0 45px; }
        @media (max-width: 375px) { padding: 0 35px; }
        @media (max-width: 360px) { padding: 0 25px; }
        img { max-width: 100%; max-height: 70px; }
        &.small-logo {
          img { max-height: 55px;
            @include media-breakpoint-down(xs) { max-height: 45px; }
          }
        }
      }

      &:nth-child(-n+4) { @include media-breakpoint-up(sm) { padding-top: 0; }  }
      &:nth-child(-n+2) { @include media-breakpoint-down(xs) { padding-top: 0; } }
    }
  }

}

.flexBackground { position: fixed; height : 100%; width : 100%; top : 0; left : 0; z-index : 5; overflow: hidden;
  canvas { position: absolute; top: 0; left: 0; }
  }

.forestBackground {
  position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 0; background: url($image-url + "royaltri-branding-print-web-marketing-montreal-forest-bkgnd.jpg") no-repeat center center; background-size:cover;
}


.error-404 {
  .wrapper { margin-bottom: 0 !important; position:static; }
  .main-header.clone { display: none; }
  .main-footer { display: none; }
  &.has-search {
    .wrapper { position: relative; }
    }
  }
.error-page { @extend %section; padding-top: calc(6.7rem + 76px); @include mQ($tablet) { padding-top: calc(5.7rem + 76px); }
  .parallax-group { position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 50; overflow: hidden;
    .bg { position: absolute; width: 100%; height: 100%; top: 0; left: 0; bottom: 0; right: 0; background-size: cover; background-position:bottom center;
      &.stars-cluster { transform: scale(1.03); z-index: 60; }
      &.forest-trees { transform: scale(1.06); z-index: 80; }
      }
    }
    .stars { position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 70; }
  .container { position: relative; z-index:100; padding-left:75px; padding-right: 75px;  @include mQ($tablet) { padding-left:65px; padding-right:65px; } @include mQ($mobile) { padding-left:54px; padding-right:54px; } }
  h3 { color: $white;
    &:before { border-color: $white; }
    }
  .content { @extend %section; padding-bottom:0; padding-left: 8%; padding-right:8%; color: $white; @include mQ($tablet) {  }
    p {
      br { @include mQ($mobile-xs) { display: none; } }
      }
    }

  h5 { color: #a46400; font-size: 6.45rem; padding-top: 5rem; font-weight: 600; margin-bottom: 0; line-height: 5.85rem; }

  }

.award-nominated { position: fixed; bottom: 15px; right: 15px; z-index: 25;
  @include mQ($mobile) { bottom: 10px; right: 10px; }

  ul { margin: 0; padding: 0; list-style: none;
    li { max-width: 70px; margin-bottom: 10px; transition: .3s opacity cubic-bezier(.165,.84,.44,1); vertical-align: bottom; position: relative;
      @include mQ($tablet) { max-width: 65px; }
      @include mQ($mobile) { max-width: 55px; display: inline-block; margin-bottom: 0; margin-right: 7px;
        &:last-child { margin-right: 0; }
      }
      @include mQ($mobile-xs) { max-width: 45px; }
      &:last-child { margin-bottom: 0; }
      &.hide { opacity: 0; z-index: -10; }
      &:nth-child(6n+1) { transition-delay: 1s; }
      &:nth-child(6n+2) { transition-delay: .8s; }
      &:nth-child(6n+3) { transition-delay: .6s; }
      &:nth-child(6n+4) { transition-delay: .4s; }
      &:nth-child(6n+5) { transition-delay: .2s; }
      &:nth-child(6n) { transition-delay: 0s; }
      a { text-align: center; opacity: 1; display: block;
        &:before { height:0; }
      }
      img { max-width: 100%; height: auto; border-radius: 50%; }
      svg {}
      img,svg { box-shadow: 0 3px 5px rgba(0,0,0,.7); }
      &.awwwards { max-width: 45px; margin-left: 12px;
        img { border-radius: 0; @include mQ($tablet) { max-width: 50px; } @include mQ($mobile) { max-width: 45px; } @include mQ($mobile-xs) { max-width: 35px; } }
      }
      &.csswinner { max-width: 80px; @include mQ($mobile) { max-width: 65px; } @include mQ($mobile-xs) { max-width: 55px; }
        img { border-radius:0; box-shadow:none; margin-left: -10px; @include mQ($mobile) { margin-left: 0; } }
      }
      &.web-guru { max-width: 60px; margin: 12px 0 12px 5px; @include mQ($mobile) { margin-top: 0; margin-bottom: 0; }
        img { box-shadow: none; border-radius: 0; }
      }
    }
  }
  &.hide { z-index: -15; opacity: 0; transition-delay: 1.2s; }
}

.has-nav {
  .award-nominated { z-index: -1; }
}

.testimonials-block { position: relative; padding-top: 45px; padding-bottom:60px; max-width: 550px; margin:0 auto;
	@include media-breakpoint-down(sm) {
		width: 100%; padding: 45px 55px;
	}
	&:before { content: open-quote; font-weight: 800; color: #e8e8e8; font-size: 12.2rem; position: absolute; top: 76px; left: -75px; line-height: 0;
		@include media-breakpoint-down(sm) {
			left: 0; top: 68px;
		}
	}
	p, h6 { position: relative; }
	h6 { font-style: italic; font-weight: $font-weight-normal; text-transform: uppercase; line-height: 1.8; position: relative;  padding-left: 22px; padding-right: 20px; margin-bottom: 0;
		span { display: block; }
		&:before { content: "\2014 \00A0"; left:0; position: absolute; top: -2px; }
	}
}

.news-detail .testimonials-block { padding-bottom: 0; }

.content-page {
  .wrapper { background: transparent; }
}

.content-section { @extend %section; color: $white;
  @include mQ($mobile) { background-attachment: scroll; }
  &:first-of-type { padding-top: calc(6.7rem + 76px); }
  .container { padding: 0 76px;
    @include mQ($tablet) { padding: 0 15px; }
    @include mQ($mobile) { padding: 0 55px; }
    }
  a { color: $white;
    &:before { background-color: $white; }
    &:hover { color: $white; }
    }
  h1, h3 { color: $white;
    &:before { border-color: $white; }
  }
  h6, h4 { font-weight: $font-weight-normal; text-transform: uppercase; }
    p { font-size: 1rem; line-height: 30px; margin-bottom: 1.1rem; font-weight:300;
      @include mQ($mobile) { font-weight:400; }
      &:last-of-type { margin-bottom: 0; }
      }
}

.content-details { @extend %section;
  h6 { line-height: 1.5; font-weight: 600; margin-bottom: 0.3rem; }
  }